﻿define("domSearchHelper", ['jquery'], (function ($) {
    var domSearchHelper = {
   
        get$SectionFromContainedElement: function ($containedElement) {
            var self = this;
            var $section = $containedElement.closest(self.selectors.fieldSection);

            return $section;
        },

        get$GroupFromContainedElement: function ($containedElement) {
            var self = this;
            var $group = $containedElement.closest(self.selectors.fieldGroup);

            return $group;
        },

        getFieldIDFromFieldClass: function ($field) {
            return $field.data("fieldId");
        },

        getFieldLookupIDFromFieldClass: function ($field) {
            return $field.data("fieldLookupId");
        },

        getFieldLookupSourceIDFromFieldClass: function ($field) {
            return $field.data("fieldLookupSourceId");
        },

        getValueFieldIDFromFieldClass: function ($field) {
            var data = $field.data();
            return "field-value-" + data.fieldId + "-" + (data.sequenceNumber || "0");
        },

        getCheckedFieldIDFromFieldClass: function ($field) {
            var data = $field.data();
            return "field-checked-" + data.fieldId + "-" + (data.sequenceNumber || "0");
        },

        getValueFieldFromFieldClass: function ($field) {
            return $("#" + this.getValueFieldIDFromFieldClass($field));
        },

        getCheckedFieldFromFieldClass: function ($field) {
            return $("#" + this.getCheckedFieldIDFromFieldClass($field));
        },

        getClassWithPrefix: function ($element, classPrefix) {
            var self = this;
            var className = self.getClassesWithPrefix($element, classPrefix)[0];
            return className;
        },

        getClassesWithPrefix: function ($element, classPrefix) {
            var classes = $element.attr("class").split(/\s+/);
            var classNames = classes.filter(function (className) { return className.indexOf(classPrefix) === 0; });
            return classNames;
        },

        getClassesWithoutPrefix: function ($element, classPrefix) {
            var classes = $element.attr("class").split(/\s+/);

            var classNames = [];

            for (let i = 0; i < classes.length; i++) {
                var tempClass = classes[i];

                if (tempClass.indexOf(classPrefix) === 0 && tempClass.length > classPrefix.length) {
                    classNames.push(tempClass.substring(classPrefix.length));
                }
            }

            return classNames;
        },

        $elementHasClassWithPrefix: function ($element, classPrefix) {
            var classes = $element.attr("class").split(/\s+/);            
            var hasClassWithPrefix = classes.some(function (className) { return className.indexOf(classPrefix) === 0; });
            return hasClassWithPrefix;
        },

        $elementHasClassWithSufix: function ($element, classSufix) {
            var classes = $element.attr("class").split(/\s+/);
            var hasClassWithSufix = classes.some(function (className) { return className.indexOf(classSufix) === className.length - classSufix.length; });
            return hasClassWithSufix;
        },

        get$ElementsWithClassPrefix: function ($container, classPrefix) {
            var self = this;

            var $elements = $container.find("[class*='" + classPrefix + "']").filter(function () {
                var $element = $(this);
                return self.$elementHasClassWithPrefix($element, classPrefix);
            });

            return $elements;
        },

        selectors: {
            fieldSection: ".field-section",
            fieldGroup: ".field-group"
        }
    };

    return domSearchHelper;
}));