﻿define("utils", ["jquery"], (function () {
    var utils = {
        getFormattedDataList: function (dataList, dataMember, plainText) {
            var formattedData = "";
            var i;

            plainText = plainText || false;

            var listBegin = plainText ? "" : "<ul>";
            var listEnd = plainText ? "" : "</ul>";
            var listItemBegin = plainText ? "" : "<li>";
            var listItemEnd = plainText ? "" : "</li>";

            formattedData += listBegin;

            for (i = 0; i < dataList.length; i++) {
                formattedData += listItemBegin + dataList[i][dataMember] + listItemEnd + "\n";
            }

            formattedData += listEnd;

            return formattedData;
        },

        isNumber: function (n) {
            return (!isNaN(parseFloat(n, 10)) && isFinite(n));
        },

        arrayRemoveItem: function (sourceArray, itemToRemove) {
            var index;
            for (index = 0; index < sourceArray.length; index++) {
                if (sourceArray[index].toString() === itemToRemove.toString()) {
                    sourceArray.splice(index, 1);
                }
            }
        }
    };

    return utils;
}));