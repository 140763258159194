﻿// ReSharper disable NativeTypePrototypeExtending
define("polyfill", [], (function () {
    var polyfill = {
        init: function () {
            var self = this;

            self.polyfillStringIncludes();
            self.polyfillStringEndsWith();
            self.polyfillArraySome();
            self.polyfillArrayEvery();
            self.polyfillArrayIncludes();
            self.polyfillArrayReduce();
            self.polyfillNumberIsInteger();
        },

        polyfillStringIncludes: function () {
            if (!String.prototype.includes) {
                String.prototype.includes = function (search, start) {
                    if (typeof start !== "number") {
                        start = 0;
                    }

                    if (start + search.length > this.length) {
                        return false;
                    } else {
                        return this.indexOf(search, start) !== -1;
                    }
                };
            }
        },

        polyfillStringEndsWith: function () {
            if (!String.prototype.endsWith) {
                String.prototype.endsWith = function (searchString, position) {
                    var subjectString = this.toString();
                    if (typeof position !== "number" || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                        position = subjectString.length;
                    }
                    position -= searchString.length;
                    var lastIndex = subjectString.lastIndexOf(searchString, position);
                    return lastIndex !== -1 && lastIndex === position;
                };
            }
        },

        polyfillArraySome: function () {
            // Production steps of ECMA-262, Edition 5, 15.4.4.17
            // Reference: http://es5.github.io/#x15.4.4.17
            if (!Array.prototype.some) {
                Array.prototype.some = function (fun/*, thisArg*/) {
                    'use strict';

                    if (this == null) {
                        throw new TypeError('Array.prototype.some called on null or undefined');
                    }

                    if (typeof fun !== 'function') {
                        throw new TypeError();
                    }

                    var t = Object(this);
                    var len = t.length >>> 0;

                    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
                    for (var i = 0; i < len; i++) {
                        if (i in t && fun.call(thisArg, t[i], i, t)) {
                            return true;
                        }
                    }

                    return false;
                };
            }
        },

        polyfillArrayEvery: function () {
            if (!Array.prototype.every) {
                Array.prototype.every = function (callbackfn, thisArg) {
                    'use strict';
                    var T, k;

                    if (this == null) {
                        throw new TypeError('this is null or not defined');
                    }

                    // 1. Let O be the result of calling ToObject passing the this 
                    //    value as the argument.
                    var O = Object(this);

                    // 2. Let lenValue be the result of calling the Get internal method
                    //    of O with the argument "length".
                    // 3. Let len be ToUint32(lenValue).
                    var len = O.length >>> 0;

                    // 4. If IsCallable(callbackfn) is false, throw a TypeError exception.
                    if (typeof callbackfn !== 'function') {
                        throw new TypeError();
                    }

                    // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 1) {
                        T = thisArg;
                    }

                    // 6. Let k be 0.
                    k = 0;

                    // 7. Repeat, while k < len
                    while (k < len) {

                        var kValue;

                        // a. Let Pk be ToString(k).
                        //   This is implicit for LHS operands of the in operator
                        // b. Let kPresent be the result of calling the HasProperty internal 
                        //    method of O with argument Pk.
                        //   This step can be combined with c
                        // c. If kPresent is true, then
                        if (k in O) {

                            // i. Let kValue be the result of calling the Get internal method
                            //    of O with argument Pk.
                            kValue = O[k];

                            // ii. Let testResult be the result of calling the Call internal method
                            //     of callbackfn with T as the this value and argument list 
                            //     containing kValue, k, and O.
                            var testResult = callbackfn.call(T, kValue, k, O);

                            // iii. If ToBoolean(testResult) is false, return false.
                            if (!testResult) {
                                return false;
                            }
                        }
                        k++;
                    }
                    return true;
                };
            }
        },

        polyfillArrayIncludes: function () {
            // https://tc39.github.io/ecma262/#sec-array.prototype.includes
            if (!Array.prototype.includes) {
                Object.defineProperty(Array.prototype, 'includes', {
                    value: function (searchElement, fromIndex) {

                        // 1. Let O be ? ToObject(this value).
                        if (this == null) {
                            throw new TypeError('"this" is null or not defined');
                        }

                        var o = Object(this);

                        // 2. Let len be ? ToLength(? Get(O, "length")).
                        var len = o.length >>> 0;

                        // 3. If len is 0, return false.
                        if (len === 0) {
                            return false;
                        }

                        // 4. Let n be ? ToInteger(fromIndex).
                        //    (If fromIndex is undefined, this step produces the value 0.)
                        var n = fromIndex | 0;

                        // 5. If n ≥ 0, then
                        //  a. Let k be n.
                        // 6. Else n < 0,
                        //  a. Let k be len + n.
                        //  b. If k < 0, let k be 0.
                        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

                        function sameValueZero(x, y) {
                            return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
                        }

                        // 7. Repeat, while k < len
                        while (k < len) {
                            // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                            // b. If SameValueZero(searchElement, elementK) is true, return true.
                            // c. Increase k by 1. 
                            if (sameValueZero(o[k], searchElement)) {
                                return true;
                            }
                            k++;
                        }

                        // 8. Return false
                        return false;
                    }
                });
            }
        },

        polyfillArrayReduce: function () {
            // Production steps of ECMA-262, Edition 5, 15.4.4.21
            // Reference: http://es5.github.io/#x15.4.4.21
            // https://tc39.github.io/ecma262/#sec-array.prototype.reduce
            if (!Array.prototype.reduce) {
                Object.defineProperty(Array.prototype, 'reduce', {
                    value: function (callback /*, initialValue*/) {
                        if (this === null) {
                            throw new TypeError('Array.prototype.reduce ' +
                                'called on null or undefined');
                        }
                        if (typeof callback !== 'function') {
                            throw new TypeError(callback +
                                ' is not a function');
                        }

                        // 1. Let O be ? ToObject(this value).
                        var o = Object(this);

                        // 2. Let len be ? ToLength(? Get(O, "length")).
                        var len = o.length >>> 0;

                        // Steps 3, 4, 5, 6, 7      
                        var k = 0;
                        var value;

                        if (arguments.length >= 2) {
                            value = arguments[1];
                        } else {
                            while (k < len && !(k in o)) {
                                k++;
                            }

                            // 3. If len is 0 and initialValue is not present,
                            //    throw a TypeError exception.
                            if (k >= len) {
                                throw new TypeError('Reduce of empty array ' +
                                    'with no initial value');
                            }
                            value = o[k++];
                        }

                        // 8. Repeat, while k < len
                        while (k < len) {
                            // a. Let Pk be ! ToString(k).
                            // b. Let kPresent be ? HasProperty(O, Pk).
                            // c. If kPresent is true, then
                            //    i.  Let kValue be ? Get(O, Pk).
                            //    ii. Let accumulator be ? Call(
                            //          callbackfn, undefined,
                            //          « accumulator, kValue, k, O »).
                            if (k in o) {
                                value = callback(value, o[k], k, o);
                            }

                            // d. Increase k by 1.      
                            k++;
                        }

                        // 9. Return accumulator.
                        return value;
                    }
                });
            }
        },

        polyfillNumberIsInteger: function () {
            Number.isInteger = Number.isInteger || function (value) {
                return typeof value === "number" &&
                    isFinite(value) &&
                    Math.floor(value) === value;
            };
        }
    };

    return polyfill;
}));