﻿define("ccisEnrollmentElegibility", ["jquery"], (function ($) {
    var ccisEnrollmentElegibility = {

        $isPassThrough: false,
        $isTaxExempt: false,
        $currentSicCode: undefined,
        $currentBackend: undefined,
        $ccisDefaultValue: undefined,
        $sicCodesLookup: [],
        $CCISEnabledForOmaha: false,

        init: function () {
            this.disableCCISEnrollmentCheckbox();
            this.getSicCodes();
            this.populateTCSIC();
            this.getSelectedValues();
            this.getCCISDefaultValue();
            this.bindEvents();
            this.addInfoIcon();
            this.getFlag_CCISEnabledForOmaha();
        },

        setSelectedSicCode: function (e) {
            this.$currentSicCode = this.getSicCodeByDescription(e.sender.value());
            this.checkCCISElegibility();
        },

        getSicCodes: function () {
            $.get("/Lookup/GetLookupForAutoComplete/15", (data) => {
                this.$sicCodesLookup = data;
                var currentSicDescription = $(this.selectors.selectedSicCodeDescription).val();
                this.$currentSicCode = this.getSicCodeByDescription(currentSicDescription);
            });
        },

        getSelectedValues: function () {
            this.$currentBackend = $(this.selectors.paymentProcessorBackend).val();
            this.$isPassThrough = $(this.selectors.mcVisaPricingTypeGroup+':checked').val() == this.mcVisaPricingType.passThrough;
            this.$isTaxExempt = $(this.selectors.ownershipTypeSelect).val() == this.ownershipType.taxExempt;
        },

        disableCCISEnrollmentCheckbox: function () {
            $(this.selectors.ccisEnrollmentCheckboxID).click((e) => {
                if(e.screenX > 0 && e.screenY > 0) //The event was triggered by user interaction
                    e.preventDefault();
            });

            //Styles to simulate the checkbox is disabled
            $(this.selectors.ccisEnrollmentCheckboxID).parent().css({ "cursor": "not-allowed" });
            var styleElem = document.head.appendChild(document.createElement("style"));
            styleElem.innerHTML = this.selectors.ccisEnrollmentCheckboxID + " ~ span:before { background-color: #cecece; }"
                + this.selectors.ccisEnrollmentCheckboxID + ":checked ~ span:before { border: 10px solid #cecece; }";
        },

        bindEvents: function () {
            this.bindPricingTypeChange();
            this.bindOwnershipTypeChange();
            this.bindBackendChange();
        },

        bindPricingTypeChange: function () {
            $(this.selectors.mcVisaPricingTypeGroup).change((e) => {
                this.$isPassThrough = e.target.value == this.mcVisaPricingType.passThrough;
                this.checkCCISElegibility();
            });
        },

        bindOwnershipTypeChange: function () {
            $(this.selectors.ownershipTypeSelect).change((e) => {
                this.$isTaxExempt = e.target.value == this.ownershipType.taxExempt;
                this.checkCCISElegibility();
            }); 
        },

        bindBackendChange: function () {
            $(this.selectors.paymentProcessorBackend).change((e) => {
                this.$currentBackend = e.target.value;
                this.checkCCISElegibility();
            });
        },

        checkCCISElegibility: function () {
            if (this.$currentBackend == this.backend.TSYS) {
                if ($(this.selectors.ccisEnrollmentCheckboxID).prop('checked') === true)
                    $(this.selectors.ccisEnrollmentCheckboxID).click();    
                return;
            }

            if (this.$currentBackend == this.backend.Omaha && !this.$CCISEnabledForOmaha) {
                if ($(this.selectors.ccisEnrollmentCheckboxID).prop('checked') === true)
                    $(this.selectors.ccisEnrollmentCheckboxID).click();
                return;
            }

            var isCCISElegible = !this.$isTaxExempt && this.$isPassThrough && !this.sicCodeIsExcluded();

            if ((isCCISElegible && $(this.selectors.ccisEnrollmentCheckboxID).prop('checked') === false) ||
                (!isCCISElegible && $(this.selectors.ccisEnrollmentCheckboxID).prop('checked') === true)) {
                $(this.selectors.ccisEnrollmentCheckboxID).click();
                $(this.selectors.ccisEnrollmentValueHidden).val(this.$ccisDefaultValue);
                $(this.selectors.ccisEnrollmentValueLabel).html(this.$ccisDefaultValue +' %');
            }
                
        },

        getCCISDefaultValue: function () {
            this.$ccisDefaultValue = $(this.selectors.ccisEnrollmentValueLabel).attr('data-package-default-value') ?? '75';
        },

        sicCodeIsExcluded: function () {
            switch (this.$currentBackend) {
                case this.backend.Omaha: {
                    return this.sicExlusionList.omaha.findIndex(x => x == this.$currentSicCode) >= 0;
                }
                case this.backend.FDNorth: {
                    return this.sicExlusionList.fdNorth.findIndex(x => x == this.$currentSicCode) >= 0;
                }
            }
            return false;
        },

        getSicCodeByDescription: function (sicDescription) {
            return this.$sicCodesLookup.find(s => s.Description == sicDescription)?.SicCode;
        },

        

        populateTCSIC: function () {
            for (var i = 3000; i <= 3999; i++) {
                this.sicExlusionList.omaha.push(i);
                this.sicExlusionList.fdNorth.push(i);
            }
        },

        addInfoIcon: function () {
            let infoIcon = document.createElement('span');
            let infoIconId = 'ccis-info-icon';
            infoIcon.className = 'glyphicon glyphicon-info-sign';
            infoIcon.id = infoIconId;
            $(this.selectors.ccisEnrollmentCheckboxID).parent().parent().append(infoIcon);
            $('#' + infoIconId).popover({
                content: this.infoIconText,
                trigger: 'hover',
                container: 'body'
            });
        },

        getFlag_CCISEnabledForOmaha: function () {
            this.$CCISEnabledForOmaha = $("#Flag_CCISEnabledForOmaha").attr('data') === "true";
        },

        infoIconText: "Commercial Card Interchange Service (“CCIS”). With CCIS, when transactions don’t include any tax information, the merchant's sales tax will be computed based on the applicable rate at their location to allow them to obtain the best interchange. When CCIS computes the sales tax on behalf of the merchant, they will retain 25% of the resulting interchange savings. If a transaction is fully or partially exempt, they should enter the tax amount (even if that amount is $0.00) as CCIS applies at their local tax rate to the full amount of the transactions when the prompt is bypassed.",

        selectors: {
            ccisEnrollmentCheckboxID: '#field-selection-16263-0-19012',
            mcVisaPricingTypeGroup: '#field-radio-10101-0 input[type=radio]',
            ownershipTypeSelect: '#field-value-16469-0',
            sicCodeDescription: '#field-value-16379-0_listbox',
            selectedSicCodeDescription: '#field-value-16379-0',
            paymentProcessorBackend: '#field-value-21269-0',
            ccisEnrollmentValueHidden: '#field-value-21401-0',
            ccisEnrollmentValueLabel: '#field-value-21401-0 ~ label.form-control.readonly'
        },

        sicExlusionList: {
            omaha: [7011, 5812],
            fdNorth: [7011, 5812, 5814, 4468, 5541, 5542, 5499, 5983, 7511, 9752, 4111, 4131, 4215, 4784, 8211, 8220, 8398, 8661, 9211, 9222, 9311, 9399, 9402]
        },

        mcVisaPricingType: {
            passThrough: '2'
        },

        ownershipType: {
            taxExempt: 'TaxExempt'
        },

        backend: {
            FDNorth: '9C9D8B3F-9072-4D5D-8AE6-03ADB3F7A480',
            Omaha: '8FD5B3DB-E080-4A11-9B9F-1402FA57790B',
            TSYS: '0AE76EE1-2472-41A8-9D73-1BF3772F21DB'
        }
    };
    return ccisEnrollmentElegibility;
}));