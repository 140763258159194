﻿define("ruleEngine", ["jquery", "jquery-validate", "observable", "equipmentPeripheralHelper", "notificationPopupHelper", "polyfill"/*, "kendo.all.min"*/], function ($, _, ko, equipmentPeripheralHelper, notificationPopupHelper) {
    var equipmentTags = jsLookups.equipmentTags;
    var lookupTags = jsLookups.lookupTags;

    var equipmentScopes = {
        "Terminal":     "a81829ad-29f6-44f3-ba60-1c9139ad8242",
        "Software":     "f5f4f45f-3a0d-4015-98ec-0dde1f4c9e69",
        "Gateway":      "fbf1a817-22bf-4b77-b05f-47897c260127",
        "Equipment":    "7491ca1f-695e-4348-a91f-fee7e6bd54b1",
        "Accessory":    "69f1a95d-18d2-472e-8928-660b787a1366"
    };

    function rawScopeInstance (initialTagMap, parentInstance) {
        var fieldObservables = {};
        var tagOverrides = ko.observable({});

        function addTag (tag) {
            var tagMap = tagOverrides();
            if (tag in tagMap) {
                tagMap[tag]++;
            } else {
                tagMap[tag] = 1;
            }
            tagOverrides(tagMap);
        }

        function removeTag (tag) {
            var tagMap = tagOverrides();
            if (tag in tagMap) {
                tagMap[tag]--;
                if (tagMap[tag] < 1)
                    delete tagMap[tag];
            }
            tagOverrides(tagMap);
        }

        $.each(initialTagMap, function (field, tagsObservable) {
            fieldObservables[field] = tagsObservable;
        });

        // this pivots from a map of the tags for each field to a map of counts for each tag
        var allTags = ko.computed(function () {
            var tagArray = $.map(fieldObservables, function (tags) { return tags(); });
            return tagArray.reduce(function (tagMap, tag) {
                if (tag in tagMap) {
                    tagMap[tag]++;
                } else {
                    tagMap[tag] = 1;
                }
                return tagMap;
            }, $.extend({}, tagOverrides()));
        });

        var tagObservables = {}; // this is merely a cache for improved performance

        function getTagObservable (tag) {
            return tagObservables[tag] || (tagObservables[tag] = ko.computed(function () {
                return allTags()[tag] || 0;
            }));
        }

        return {
            addTag: addTag,
            removeTag: removeTag,
            getTagObservable: getTagObservable,
            parentInstance: parentInstance
        };
    }

    var calculatedScopeConfig = {
        "00000000-0000-0000-0000-000000000000": { // application
            matchInstance: false,
            searchRawPlacements: [
                "00000000-0000-0000-0000-000000000000", // application
                "12f62676-e550-4457-a6f7-94ec0291de50", // principal
                "fbf1a817-22bf-4b77-b05f-47897c260127", // gateway
                "f5f4f45f-3a0d-4015-98ec-0dde1f4c9e69", // software
                "a81829ad-29f6-44f3-ba60-1c9139ad8242", // terminal
                "7491ca1f-695e-4348-a91f-fee7e6bd54b1", // equipment
                "69f1a95d-18d2-472e-8928-660b787a1366"  // accessory (unified)
            ]
        },
        "e6a5a752-b210-48b2-a1ca-6a3bbb189f1c": { // all principals
            matchInstance: false,
            searchRawPlacements: [
                "12f62676-e550-4457-a6f7-94ec0291de50" // principal
            ]
        },
        "12f62676-e550-4457-a6f7-94ec0291de50": { // principal
            matchInstance: true,
            searchRawPlacements: [
                "12f62676-e550-4457-a6f7-94ec0291de50" // principal
            ]
        },
        "d3521c5b-d8dd-495e-a2c5-9c02e50de11b": { // all equipment
            matchInstance: false,
            searchRawPlacements: [
                "fbf1a817-22bf-4b77-b05f-47897c260127", // gateway
                "f5f4f45f-3a0d-4015-98ec-0dde1f4c9e69", // software
                "a81829ad-29f6-44f3-ba60-1c9139ad8242", // terminal
                "7491ca1f-695e-4348-a91f-fee7e6bd54b1", // equipment
                "69f1a95d-18d2-472e-8928-660b787a1366"  // accessory (unified)
            ]
        },
        "c67b3c57-938b-46de-a88e-7c9bbb0f7859": { // all gateways
            matchInstance: false,
            searchRawPlacements: [
                "fbf1a817-22bf-4b77-b05f-47897c260127"  // gateway
            ]
        },
        "fbf1a817-22bf-4b77-b05f-47897c260127": { // gateway
            matchInstance: true,
            searchRawPlacements: [
                "fbf1a817-22bf-4b77-b05f-47897c260127"  // gateway
            ]
        },
        "2a523199-ac1b-4084-bbdf-418538a8d620": { // all software
            matchInstance: false,
            searchRawPlacements: [
                "f5f4f45f-3a0d-4015-98ec-0dde1f4c9e69"  // software
            ]
        },
        "f5f4f45f-3a0d-4015-98ec-0dde1f4c9e69": { // software
            matchInstance: true,
            searchRawPlacements: [
                "f5f4f45f-3a0d-4015-98ec-0dde1f4c9e69"  // software
            ]
        },
        "2a0f1152-f826-48e4-8720-e29d38b36263": { // all terminals
            matchInstance: false,
            searchRawPlacements: [
                "a81829ad-29f6-44f3-ba60-1c9139ad8242"  // terminal
            ]
        },
        "a81829ad-29f6-44f3-ba60-1c9139ad8242": { // terminal
            matchInstance: true,
            searchRawPlacements: [
                "a81829ad-29f6-44f3-ba60-1c9139ad8242"  // terminal
            ]
        },
        "7491ca1f-695e-4348-a91f-fee7e6bd54b1": { // equipment
            matchInstance: true,
            searchRawPlacements: [
                "7491ca1f-695e-4348-a91f-fee7e6bd54b1", // equipment
                "69f1a95d-18d2-472e-8928-660b787a1366"  // accessory (unified)
            ]
        }
    };

    function calculatedScope(config, mergedRaw) {
        var tagObservables = {}; // cache for performance only

        return function (instance, tag) {
            return tagObservables[tag] || (tagObservables[tag] = ko.computed(function () {
                var tagCountObservables = $.map(config.searchRawPlacements, function (placement) {
                    var rawInstances = mergedRaw[placement]();
                    return $.map(rawInstances, function (rawInstance, instanceId) {
                        if (config.matchInstance && instance !== instanceId && instance !== rawInstance.parentInstance) {
                            return undefined;
                        }
                        return rawInstance.getTagObservable(tag);
                    });
                });
                return tagCountObservables.reduce(function (sum, tagObservable) {
                    return sum + tagObservable();
                }, 0);
            }));
        };
    }

    function newTagScope (previousRaw) {
        var tagScope = {
            calculated: {},
            raw: {}
        };

        $.each(calculatedScopeConfig["00000000-0000-0000-0000-000000000000"].searchRawPlacements, function (__, placement) {
            var previousInstances = previousRaw[placement];
            var newInstances = previousInstances ? $.extend({}, previousInstances()) : {};
            tagScope.raw[placement] = ko.observable(newInstances);
        });

        $.each(calculatedScopeConfig, function (scope, config) {
            tagScope.calculated[scope] = calculatedScope(config, tagScope.raw);
        });

        return tagScope;
    }

    var ruleDataViewModel = {
        init: function () {
            this.initializeContainer(document);
        },

        initializeModalContainer: function (container) {
            var self = this;

            self.pushTagScope();
            self.initializeContainer(container);
        },

        initializeContainer: function (container) {
            var self = this;

            //self.init$Elements(container);
            self.bindEvents(container);
            self.initState(container);

            self.initialized = true;
        },
        
        //init$Elements: function (container) {
        //    var self = this;

        //},

        bindEvents: function (container) {
            var self = this;

            var initialTags = {};
            var scopeRelationships = {};
            var $allRuleData = $(self.selectors.ruleData, container).add($(container).prev(self.selectors.ruleData));
            var ruleDataSelector = self.selectors.tagSummary
                           + "," + self.selectors.fieldChange
                           + "," + self.selectors.behaviorParameters
                           + "," + self.selectors.fieldCriteria;

            $allRuleData.has(ruleDataSelector).each(function () {
                var $ruleData = $(this);
                var data = $ruleData.data();
                scopeRelationships[data.instance] = data.parentInstance;

                self.bindTagSummaries($ruleData.children(self.selectors.tagSummary), data, initialTags);
                self.bindFieldChanges($ruleData.children(self.selectors.fieldChange), data, initialTags, container);

                // if a behavior can exclude the field, it will set the exclude property in data, which criteria fields must honor
                self.bindBehaviors($ruleData.children(self.selectors.behaviorParameters), $ruleData, data, initialTags);
                self.bindCriteriaFields($ruleData.children(self.selectors.fieldCriteria), data);
            });

            // and the behaviors that can't live inside rule-data
            self.bindOptionBehaviors(container);

            // all the tag summaries and field changes need to be initialized before we can initialize tag scopes
            self.initializeTagScopes(initialTags, scopeRelationships);

            // all tag scopes and criteria fields need to be initialized before we can initialize any rules

            $allRuleData.has(self.selectors.ruleParameters).each(function () {
                var $ruleData = $(this);
                var instance = $ruleData.data("instance");

                self.bindRules($ruleData.children(self.selectors.ruleParameters), instance);
            });
        },

        bindTagSummaries: function ($summary, data, initialTags) {
            var summary = !!$summary.length && $summary.data("summary");
            if (summary) {
                var placement = initialTags[data.placement] = initialTags[data.placement] || {};
                $.each(summary, function (instanceUid, fields) {
                    var instance = placement[instanceUid] = placement[instanceUid] || {};
                    $.each(fields, function (field, tags) {
                        instance[field] = ko.observable(tags);
                    });
                });
            }
        },

        bindFieldChanges: function ($fields, data, initialTags, container) {
            if (!$fields.length)
                return;

            var self = this;

            var fieldModel = self.getFieldModel(data);

            $fields.each(function () {
                var $fieldChange = $(this);

                $.each($fieldChange.data("parameters") || [], function (__, fieldData) {
                    self.fieldChangeActions[fieldData.action].call(self, data, fieldModel, fieldData.value, initialTags, container);
                });
            });
        },

        bindBehaviors: function ($behavior, $ruleData, data, initialTags) {
            if (!$behavior.length)
                return;

            var self = this;

            var instance = data.instance;

            var hasIncludeBehavior = false;

            function isIncludeSet (action) {
                return action.action === "includeSet";
            }

            var hasRequiredBehavior = false;

            var requiredActions = ["requiredSet", "requiredUseDefault"];

            function altersRequired (action) {
                return requiredActions.includes(action.action);
            }

            var hasTagBehavior = false;

            var tagActions = ["tagsAdd", "tagsRemove", "tagsInclude", "tagsExclude", "accessoryTagsAdd", "accessoryTagsRemove"];

            function altersTags (action) {
                return tagActions.includes(action.action);
            }

            $.each($behavior.data("parameters") || [], function (__, behavior) {
                hasIncludeBehavior = hasIncludeBehavior || behavior.onTrue.some(isIncludeSet) || behavior.onFalse.some(isIncludeSet);
                hasRequiredBehavior = hasRequiredBehavior || behavior.onTrue.some(altersRequired) || behavior.onFalse.some(altersRequired);
                hasTagBehavior = hasTagBehavior || behavior.onTrue.some(altersTags) || behavior.onFalse.some(altersTags);

                var ruleSelector = "#rule-parameters-" + behavior.rule + "-" + (behavior.isMain ? "00000000-0000-0000-0000-000000000000" : instance);
                $(ruleSelector).on("iPayment.ruleResult", function (e, result) {
                    var actions = result ? behavior.onTrue : behavior.onFalse;
                    $.each(actions, function (___, action) {
                        self.behaviorActions[action.action].call(self, $ruleData, action.value);
                    });
                });
            });

            if (hasIncludeBehavior) {
                data.include = ko.observable(undefined);
            }

            if (hasTagBehavior) {
                initialTags[data.placement] = initialTags[data.placement] || {};
                initialTags[data.placement][data.instance] = initialTags[data.placement][data.instance] || {};
            }

            if (data.readOnly !== "False")
                return;

            var required, requiredComputed;

            if (hasRequiredBehavior) {
                required = "#" + $ruleData.attr("id") + ".required";
                data.requiredObservable = ko.observable(data.required === "True");
                requiredComputed = hasIncludeBehavior
                    ? ko.computed(function() {
                        return data.include() && data.requiredObservable();
                    })
                    : data.requiredObservable;
            } else if (data.required === "True") {
                if (hasIncludeBehavior) {
                    required = "#" + $ruleData.attr("id") + ".required";
                    requiredComputed = data.include;
                } else {
                    required = true;
                }
            } else {
                return;
            }

            if (requiredComputed) {
                requiredComputed.subscribe(function (value) {
                    if (value) {
                        $ruleData.addClass("required");
                    } else {
                        $ruleData.removeClass("required");
                    }
                });
            }

            var fieldModel = self.getFieldModel(data);
            var control = fieldModel.getControl();
            var requiredMessage = $ruleData.next().find(".field-name:first").text().trim() + fieldModel.requiredMessage;

            control.addClass("has-validation");
            control.rules("add", {
                required: required,
                messages: {
                    required: requiredMessage
                }
            });
        },

        bindCriteriaFields: function ($fields, data) {
            if (!$fields.length)
                return;

            var self = this;

            var fieldModel = self.getFieldModel(data);

            $fields.each(function () {
                var $fieldCriteria = $(this);
                var fieldData = $fieldCriteria.data();

                // to shrink the closure, capture just what we need
                var include = data.include;
                var comparison = self.comparisons[fieldData.comparisonOperation];
                var valueObservable = fieldModel.valueObservable;
                var comparisonValue = (fieldData.comparisonValue === null || fieldData.comparisonValue === undefined)
                                    ? ""
                                    : String(fieldData.comparisonValue).trim();
                var ifExcluded = fieldData.ifExcluded;

                function compare () {
                    var fieldValue = valueObservable();
                    fieldValue = fieldValue ? fieldValue.trim() : "";
                    return comparison(fieldValue, comparisonValue);
                }

                fieldData.satisfied = ko.isObservable(include)
                                    ? ko.computed(function () {
                                        return include() ? compare() : ifExcluded;
                                    })
                                    : ko.computed(compare);
            });
        },

        bindRules: function ($rules, instance) {
            if (!$rules.length)
                return;

            var self = this;

            $rules.each(function () {
                var $rule = $(this);
                var criteria = $rule.data("criteria");

                var criteriaObservables = $.map(criteria, function (criteriaSet) {
                    var comparisonObservables = $.map(criteriaSet.fields, function (field) {
                            var fieldCriteriaSelector = "#field-criteria-" + field.id + "-" + (field.isMain ? "00000000-0000-0000-0000-000000000000" : instance);
                            return $(fieldCriteriaSelector).data("satisfied") || ko.observable(field.ifMissing);
                        })
                        .concat($.map(criteriaSet.tags, function (tag) {
                            var tagObservable = self.getTagObservable(tag.tag, tag.scope, instance);
                            var min = tag.min;
                            var max = tag.max;
                            return $.isNumeric(max)
                                ? ko.computed(function () {
                                    var value = tagObservable();
                                    return value >= min && value <= max;
                                })
                                : ko.computed(function () {
                                    var value = tagObservable();
                                    return value >= min;
                                });
                        }));

                    return ko.computed(function() {
                        return comparisonObservables.every(function(comparisonObservable) { return comparisonObservable(); });
                    });
                });

                var ruleResult = ko.computed(function () {
                    return criteriaObservables.some(function (criteriaObservable) { return criteriaObservable(); });
                });

                $rule.data("ruleResult", ruleResult);

                function triggerBehaviors (result) {
                    $rule.trigger("iPayment.ruleResult", [ result ]);
                }

                ruleResult.subscribe(triggerBehaviors);
                triggerBehaviors(ruleResult()); // fire once to initialize behaviors
            });
        },

        bindOptionBehaviors: function (container) {
            var self = this;

            $(self.selectors.optionBehaviors, container).each(function () {
                var $option = $(this);
                var $ruleData = {
                    next: function () {
                        return $option;
                    }
                };

                $.each($option.data("parameters") || [], function (__, behavior) {
                    var ruleSelector = "#rule-parameters-" + behavior.rule + "-" + (behavior.isMain ? "00000000-0000-0000-0000-000000000000" : instance);
                    $(ruleSelector).on("iPayment.ruleResult", function (___, result) {
                        var actions = result ? behavior.onTrue : behavior.onFalse;
                        $.each(actions, function (____, action) {
                            self.behaviorActions[action.action].call(self, $ruleData, action.value);
                        });
                    });
                });
            });
        },

        getFieldModel: function (data) {
            return data.fieldModel || (data.fieldModel = function () {
                function checkedAsString ($checkbox) {
                    return $checkbox.prop("checked") ? "True" : "False";
                }

                function radioValue ($radioGroup) {
                    return $radioGroup.filter(":checked").val();
                }

                function asBoolean(value) {
                    if (typeof value === "string") {
                        return value.toLowerCase() === "true";
                    }
                    return !!value;
                }

                var readOnly = asBoolean(data.readOnly);

                function newFieldModel (selector, getValue, setValue, disable, events, requiredMessage, alwaysReadOnly) {
                    function getControl () {
                        return $(selector);
                    }

                    var $control = getControl();
                    var setBeforeChange = $.noop;
                    var setDomHook = $.noop;
                    var valueObservable;

                    if (alwaysReadOnly) {
                        valueObservable = ko.observable(); // there is no value
                    } else if (readOnly) {
                        var initialValue = getValue($control);
                        valueObservable = ko.computed({
                            read: function () { return initialValue; },
                            write: function () {}
                        });
                    } else {
                        var observable = ko.observable(getValue($control));

                        var domHook = { // its own object to avoid certain closure issues
                            modelToDom: function (value) { return setValue(getControl(), value); },
                            domToModel: function (newValue, preventDefault) {
                                observable(newValue);
                                return preventDefault ? true : undefined; // if you don't pass preventDefault, don't look for a return value
                            }
                        };

                        if (events.length) {
                            $control.on($.map(events, function (e) { return e + ".ruleEngine"; }).join(" "), function () {
                                domHook.domToModel(getValue(getControl()), function (oldValue) { setValue(getControl(), oldValue); });
                            });
                        }

                        valueObservable = ko.computed({
                            read: observable,
                            write: function (value) {
                                observable(domHook.modelToDom(value));
                            }
                        });

                        setDomHook = function (modelToDom) {
                            getControl().off(".ruleEngine"); // remove exiting hook

                            // change reverse hook
                            domHook.modelToDom = modelToDom;

                            // return func to use in new hook
                            return function (newValue, preventDefault) {
                                return domHook.domToModel(newValue, preventDefault);
                            };
                        };

                        setBeforeChange = function (func) {
                            domHook.domToModel = function (newValue, preventDefault) {
                                if (!preventDefault) { // I can't stop the feelin' anymore, so I won't ask for consent
                                    observable(newValue);
                                    return undefined; // if you don't pass preventDefault, don't look for a return value
                                }

                                var oldValue = observable();

                                var changeAccepted = func(oldValue, newValue, function () { valueObservable(newValue); });
                                if (changeAccepted)
                                    observable(newValue);
                                else
                                    preventDefault(oldValue);

                                return changeAccepted;
                            };
                        };
                    }

                    return {
                        getControl: getControl,
                        disable: readOnly ? $.noop : function (value) { disable(getControl(), value); },
                        valueObservable: valueObservable,
                        requiredMessage: requiredMessage,
                        setBeforeChange: setBeforeChange,
                        setDomHook: setDomHook
                        //TODO: other methods
                    };
                }

                switch (data.uiStyle) {
                    case "text":
                        return newFieldModel("#field-value-" + data.fieldId + "-" + data.sequenceNumber,
                                             function ($control) { return $control.val(); },
                                             function ($control, value) { $control.val(value); return value; },
                                             function ($control, value) {
                                                 var fieldIds = [
                                                     $control.attr("id"),
                                                     $control.attr("id").replace("-value", "-verify")
                                                 ];
                                                 var i;
                                                 for (i = 0; i < fieldIds.length; i++) {
                                                     var field = $("#" + fieldIds[i]);
                                                     if (field.length > 0) {
                                                         var maskedTextBox = field.data("kendoMaskedTextBox");
                                                         if (typeof maskedTextBox !== 'undefined') {
                                                             maskedTextBox.readonly(value);
                                                         } else {
                                                             field.prop("readonly", value);
                                                         }
                                                     }

                                                 }
                                             },
                                             ["change", "input"], " should not be empty", false);

                    case "button":
                        return newFieldModel("#field-button-" + data.fieldId + "-" + data.sequenceNumber,
                                             $.noop, $.noop,
                                             function ($control, value) { $control.prop("disabled", value); },
                                             [], "", true);

                    case "check":
                        return newFieldModel("#field-checked-" + data.fieldId + "-" + data.sequenceNumber,
                                             checkedAsString,
                                             function ($control, value) {
                                                 var checked = asBoolean(value);
                                                 $control.prop("checked", checked);
                                                 return checked ? "True" : "False";
                                             },
                                             function ($control, value) {
                                                 if (value) {
                                                     $control.prop("disabled", true);
                                                     if ($control.prop("checked")) {
                                                         $control.after('<input type="hidden" id="' + $control.attr("id")
                                                             + '-disabled" name="' + $control.attr("name")
                                                             + '" value="' + $control.val()
                                                             + '" />');
                                                     }
                                                 } else {
                                                     $control.prop("disabled", false);
                                                     $("#" + $control.attr("id") + "-disabled").remove();
                                                 }
                                             },
                                             ["change"], " must be checked", false);

                    case "radio":
                        var itemSelector = "#field-selection-" + data.fieldId + "-" + data.sequenceNumber + "-";
                        return newFieldModel(".field-checked-" + data.fieldId + "-" + data.sequenceNumber,
                                             radioValue,
                                             function ($control, value) {
                                                 if (value == null) { // not === so undefined will also work
                                                     $control.prop("checked", false); // uncheck all
                                                 } else {
                                                     var choiceSelector = itemSelector + value.toLowerCase();
                                                     $(choiceSelector).prop("checked", true);
                                                 }
                                                 return value;
                                             },
                                             function ($control, value) {
                                                 if (value) {
                                                     $control.prop("disabled", true).last()
                                                         .after('<input type="hidden" id="field-checked-' + data.fieldId + "-" + data.sequenceNumber
                                                             + '-disabled" name="' + $control.attr("name")
                                                             + '" value="' + radioValue($control)
                                                             + '" />');
                                                 } else {
                                                     $control.prop("disabled", false);
                                                     $("#field-checked-" + data.fieldId + "-" + data.sequenceNumber + "-disabled").remove();
                                                 }
                                             },
                                             ["change"], " must have a selection", false);

                    case "select":
                        return newFieldModel("#field-value-" + data.fieldId + "-" + data.sequenceNumber,
                                             function ($control) { return $control.val(); },
                                             function ($control, value) {
                                                 $control.val(value);
                                                 var hiddenFieldSel = $("#" + $control.attr("id") + "-disabled");
                                                 if (hiddenFieldSel.length > 0) {
                                                     hiddenFieldSel.val(value);
                                                 }
                                                 return value;
                                             },
                                             function ($control, value) {
                                                 var hiddenFieldSel = $("#" + $control.attr("id") + "-disabled");
                                                 if (hiddenFieldSel.length > 0) {
                                                     hiddenFieldSel.remove();
                                                 }
                                                 if (value) {
                                                     $control.prop("disabled", true);                                                     
                                                     $control.after('<input type="hidden" id="' + $control.attr("id")
                                                        + '-disabled" name="' + $control.attr("name")
                                                        + '" value="' + $control.val()
                                                        + '" />');                                                 
                                                 } else {
                                                     $control.prop("disabled", false);                                                    
                                                 }
                                             },
                                             ["change"], " must have a selection", false);

                    case "checklist":
                        // note that if a checkbox list field were ever made required, which would be stupid, this implementation would do stupid stuff
                        var selector = ".field-checked-" + data.fieldId + "-" + data.sequenceNumber;

                        var selectorMap = {};
                        $(selector).each(function () {
                            var $checkbox = $(this);
                            selectorMap[$checkbox.data("optionId")] = "#" + $checkbox.attr("id");
                        });

                        return newFieldModel(selector,
                                             function () {
                                                 return $.map(selectorMap, function (optionSelector, optionId) {
                                                     return optionId + "|" + ($(optionSelector).prop("checked") ? "true" : "false");
                                                 }).join(",");
                                             },
                                             function ($control, value) {
                                                 var values = value.split(",");
                                                 $.each(values, function (__, option) {
                                                     var kvp = option.split("|");
                                                     if (kvp.length === 2) {
                                                         var optionSelector = selectorMap[kvp[0]];
                                                         if (optionSelector) {
                                                             $(optionSelector).prop("checked", kvp[1].toLowerCase() === "true");
                                                         }
                                                     }
                                                 });
                                                 return value;
                                             },
                                             function ($control, value) {
                                                 $control.each(function () {
                                                     var $checkbox = $(this);
                                                     if (value) {
                                                         $checkbox.prop("disabled", true);
                                                         if ($checkbox.prop("checked")) {
                                                             $checkbox.after('<input type="hidden" id="' + $checkbox.attr("id")
                                                                 + '-disabled" name="' + $checkbox.attr("name")
                                                                 + '" value="' + $checkbox.val()
                                                                 + '" />');
                                                         }
                                                     } else {
                                                         $checkbox.prop("disabled", false);
                                                         $("#" + $checkbox.attr("id") + "-disabled").remove();
                                                     }
                                                 });
                                             },
                                             ["change"], " must have a selection", false);

                    case "label":
                        return {
                            getControl: function () {
                                return $("<input />"); // there is no control
                            },
                            disable: $.noop,
                            valueObservable: ko.observable(), // there is no value
                            requiredMessage: "", // there is no value
                            setBeforeChange: $.noop,
                            setDomHook: $.noop
                            //TODO: other methods
                        };

                    case "control":
                        return newFieldModel(data.selector,
                                             $.noop, $.noop,
                                             function ($control, value) { $control.prop("disabled", value); },
                                             [], "", true);

                    case "html":
                        return newFieldModel("#field-value-" + data.fieldId,
                            $.noop,
                            function ($control, value) {
                                $control.html(value);
                                return value;
                            },
                            $.noop,
                            [], "", false);

                    default: // error
                        return {
                            //TODO: some kind of error implementation
                        };
                }
            }());
        },

        tagScopes: [
            newTagScope({}) // the application-level scope
            // a second one is pushed for a modal dialog (equipment)
            // when there is a sub-container (accessories), that's the third one
        ],

        initializeTagScopes: function (initialTags, scopeRelationships) {
            var self = this;

            var tagScope = self.getCurrentTagScope();
            $.each(initialTags, function (placement, instances) {
                var rawInstances = tagScope.raw[placement]();

                $.each(instances, function (instance, fields) {
                    rawInstances[instance] = rawScopeInstance(fields, scopeRelationships[instance]);
                });

                tagScope.raw[placement](rawInstances);
            });
        },

        pushTagScope: function () {
            var self = this;

            var tagScope = self.getCurrentTagScope();
            self.tagScopes.push(newTagScope(tagScope.raw));
        },

        popTagScope: function () {
            var self = this;

            if (self.tagScopes.length === 1)
                throw Error("The tag scope for the main application page can't be popped, only for equipment dialogs.");

            self.tagScopes.pop();
        },

        getCurrentTagScope: function () {
            var tagScopes = this.tagScopes;
            return tagScopes[tagScopes.length - 1];
        },

        getPreviousTagScope: function () {
            var tagScopes = this.tagScopes;
            return tagScopes.length === 1 ? { calculated: {}, raw: {} } : tagScopes[tagScopes.length - 2];
        },

        getTagObservable: function(tag, scope, instance) {
            return this.getCurrentTagScope().calculated[scope](instance, tag);
        },

        comparisons: {
            "e64eb3b1-deb5-4b8a-a52d-5fd91fce7e0a": function(fieldValue, testValue) { // equals
                if ($.isNumeric(testValue)) {
                    return $.isNumeric(fieldValue) && Number(fieldValue) === Number(testValue);
                }
                return fieldValue.toLowerCase() === testValue.toLowerCase();
            },
            "73e320ad-baaf-4e5b-81a8-a0af23be62e3": function (fieldValue, testValue) { // not equals
                if ($.isNumeric(testValue)) {
                    return $.isNumeric(fieldValue) && Number(fieldValue) !== Number(testValue);
                }
                return fieldValue.toLowerCase() !== testValue.toLowerCase();
            },
            "57967b9e-7d29-4ab0-80f3-c2eb541b17f1": function (fieldValue, testValue) { // contains
                return testValue === ""
                     ? fieldValue !== ""
                     : fieldValue.toLowerCase().includes(testValue.toLowerCase());
            },
            "8d2342bd-51f0-4888-b562-f0506e5a09cd": function (fieldValue, testValue) { // greater than
                if ($.isNumeric(testValue)) {
                    return $.isNumeric(fieldValue) && Number(fieldValue) > Number(testValue); // numeric comparison
                }
                return fieldValue.toLowerCase() > testValue.toLowerCase(); // case-insensitive alpha sort
            },
            "c8fcc702-3ffb-4c17-94ae-b8cdd625b522": function (fieldValue, testValue) { // does not contain
                return testValue === ""
                    ? false
                    : !fieldValue.toLowerCase().includes(testValue.toLowerCase());
            }

            //TODO: other supported comparisons
        },

        behaviorActions: {
            includeSet: function ($ruleData, value) {
                $ruleData.data("include")(value.toLowerCase() === "true");
            },
            requiredSet: function ($ruleData, value) {
                var data = $ruleData.data();
                if (data.readOnly === "False") {
                    data.requiredObservable(value.toLowerCase() === "true");
                }
            },
            requiredUseDefault: function ($ruleData) {
                var data = $ruleData.data();
                if (data.readOnly === "False") {
                    data.requiredObservable(data.required === "True");
                }
            },
            hiddenSet: function ($ruleData, value) {
                var $element = $ruleData.next();                
                if (value.toLowerCase() === "true") {
                    $element.addClass("hidden");
                    if ($element.is("option"))
                        $element.prop("selected", false);
                    //radio buttons have their parent label element hidden
                    if ($element.is("label") && $element.children('input[type=radio]').length == 1) {
                        $element.children('input[type=radio]').prop("checked", false);                        
                    }
                } else {
                    $element.removeClass("hidden");
                }
            },
            disabledSet: function ($ruleData, value) {
                var self = this;
                var data = $ruleData.data();
                var fieldModel = self.getFieldModel(data);
                fieldModel.disable(value.toLowerCase() === "true");
            },
            valueSet: function ($ruleData, value) {
                var self = this;
                var data = $ruleData.data();
                if (self.initialized || !data.preserveSavedValue) {
                    var fieldModel = self.getFieldModel(data);
                    fieldModel.valueObservable(value);
                }
            },
            valueSaveAndSet: function ($ruleData, value) {
                var self = this;
                var data = $ruleData.data();
                var fieldModel = self.getFieldModel(data);
                data.storeData = fieldModel.valueObservable();
                if (self.initialized || !data.preserveSavedValue)
                    fieldModel.valueObservable(value);
            },
            valueRestore: function ($ruleData) {
                var self = this;
                var data = $ruleData.data();
                if ((self.initialized || !data.preserveSavedValue) && data.storeData !== undefined) {
                    var fieldModel = self.getFieldModel(data);
                    fieldModel.valueObservable(data.storeData);
                }
            },
            valueForce: function ($ruleData, value) {
                var self = this;
                var data = $ruleData.data();
                var fieldModel = self.getFieldModel(data);
                if (self.initialized || !data.preserveSavedValue)
                    fieldModel.valueObservable(value);
                fieldModel.disable(true);
            },
            tagsAdd: function ($ruleData, value) {
                var self = this;
                var data = $ruleData.data();
                var tagScope = self.getCurrentTagScope();
                var placement = tagScope.raw[data.placement];
                var instances = placement();
                var instance = instances[data.instance];
                instance.addTag(value);
            },
            tagsRemove: function ($ruleData, value) {
                var self = this;
                var data = $ruleData.data();
                var tagScope = self.getCurrentTagScope();
                var placement = tagScope.raw[data.placement];
                var instances = placement();
                var instance = instances[data.instance];
                instance.removeTag(value);
            },
            accessoryTagsAdd: function ($ruleData) {
                var self = this;
                var data = $ruleData.data();
                var tagScope = self.getCurrentTagScope();
                var placement = tagScope.raw[data.placement];
                var instances = placement();
                var instance = instances[data.instance];
                var tags = equipmentTags[data.accessoryUid] || [];
                $.each(tags, function (i, t) { instance.addTag(t); });
            },
            accessoryTagsRemove: function ($ruleData) {
                var self = this;
                var data = $ruleData.data();
                var tagScope = self.getCurrentTagScope();
                var placement = tagScope.raw[data.placement];
                var instances = placement();
                var instance = instances[data.instance];
                var tags = equipmentTags[data.accessoryUid] || [];
                $.each(tags, function (i, t) { instance.removeTag(t); });
            }
        },

        fieldChangeActions: {
            equipmentTags: function (data, fieldModel, value, initialTags) {
                // only one field change behavior for a field can set tags
                var placement = initialTags[data.placement] || (initialTags[data.placement] = {});
                var instance = placement[data.instance] || (placement[data.instance] = {});
                instance[value] = ko.computed(function () {
                    return equipmentTags[fieldModel.valueObservable()] || [];
                });
            },
            lookupTags: function (data, fieldModel, value, initialTags) {
                // only one field change behavior for a field can set tags
                var placement = initialTags[data.placement] || (initialTags[data.placement] = {});
                var instance = placement[data.instance] || (placement[data.instance] = {});
                instance[value] = ko.computed(function () {
                    return lookupTags[value][fieldModel.valueObservable()] || [];
                });
            },
            peripheralHelper: function (_, fieldModel) {
                equipmentPeripheralHelper.initDeployCheckboxBasedonPeripheral(fieldModel.getControl());
            },
            preserveSavedValue: function (data) {
                data.preserveSavedValue = true;
            },
            warnBeforeChange: function (data, fieldModel, value) {
                var warnings = JSON.parse(value);
                var instance = data.instance;

                function checkForWarning(oldValue, newValue, acceptNewValue) {
                    if (oldValue === newValue)
                        return true;

                    var warning = [];

                    $.each(warnings, function (rule, message) { // ruleResult true: no warning, ruleResult false: warning
                        if (!$("#rule-parameters-" + rule + "-" + instance).data("ruleResult")())
                            warning.push("<p>" + message + "</p>");
                    });

                    if (warning.length === 0)
                        return true;

                    warning.push("<p>Are you sure you would like to continue?</p>");

                    notificationPopupHelper.confirmSubmit({ message: warning.join("") }, acceptNewValue);

                    return false;
                }

                fieldModel.setBeforeChange(checkForWarning);
            },
            refreshElement: function (data, fieldModel, value, _, container) {
                var self = this;

                var $element = $(value, container);
                var postDataObservable = $element.data("postData");
                if (!ko.isWriteableObservable(postDataObservable) || !$.isPlainObject(postDataObservable())) {
                    postDataObservable = ko.observable({});
                    $element.data("postData", postDataObservable);

                    var computedPostData = ko.computed(function () {
                        var result = { parentUniqueUid: data.instance }; // we are assuming, correctly by happenstance but not by design, that the instance of the first field to register is in the same section as the element to be refreshed
                        $.each(postDataObservable(), function (key, observable) {
                            result[key] = observable();
                        });
                        return result;
                    });

                    var refreshUrl = $element.data("refreshUrl");

                    var additionalFields = $element.data("additionalFields");
                    if (additionalFields) {
                        var initialPostData = postDataObservable();
                        $.each(additionalFields, function (hierarchy, fakeData) {
                            fakeData.sequenceNumber = fakeData.isMain ? 0 : data.sequenceNumber;
                            var additionalFieldModel = self.getFieldModel(fakeData);
                            initialPostData[hierarchy] = additionalFieldModel.valueObservable;
                        });
                        postDataObservable(initialPostData);
                    }

                    computedPostData.subscribe(function (dataToPost) {
                        var deinitializer = self.getContainerDeinitializer($element);
                        $element.load(refreshUrl, dataToPost, function () {
                            deinitializer();
                            self.initializeContainer(this);
                        });
                    });
                }

                var postData = postDataObservable();
                postData[data.hierarchy] = fieldModel.valueObservable;
                postDataObservable(postData);
            }
        },

        initState: function (container) {
            var self = this;

            $(self.selectors.ruleData + "[data-required=True][data-read-only=False]:not(:has(span.behavior-parameters)):not([data-ui-style=check])", container)
                .each(function () {
                    var $ruleData = $(this);
                    var data = $ruleData.data();
                    var fieldModel = self.getFieldModel(data);

                    var control = fieldModel.getControl();
                    control.addClass("has-validation");
                    control.rules("add", {
                        required: true,
                        messages: {
                            required: $ruleData.next().find(".field-name:first").text().trim() + fieldModel.requiredMessage
                        }
                });
            });
        },

        modalContainerClosed: function () {
                this.popTagScope();
        },

        getContainerDeinitializer: function (container) {
            var self = this;

            var placements = {};
            $(self.selectors.ruleData, container).each(function () {
                var $ruleData = $(this);
                var data = $ruleData.data();
                var placement = placements[data.placement];
                if (!placement)
                    placements[data.placement] = placement = {};
                placement[data.instance] = true;
            });

            return function () {
                var rawTagScope = self.getCurrentTagScope().raw;
                $.each(placements,
                    function (placementUid, instanceUids) {
                        var placement = rawTagScope[placementUid];
                        var instances = placement();
                        $.each(instanceUids,
                            function (instanceUid) {
                                delete instances[instanceUid];
                            });
                        placement(instances);
                    });
            };
        },

        deleteEquipment: function (equipmentType, uniqueId) {
            var self = this;

            var tagScope = self.getCurrentTagScope();
            var placement = tagScope.raw[equipmentScopes[equipmentType]];
            var instances = placement();
            delete instances[uniqueId];
            placement(instances);
        },

        // $field: jQuery object for the div.form-group.field
        // setControl: func (value) -- set the control to the value and return a cleaned (if necessary) value to set in the model
        // returns: func(newValue, preventDefault) -- if newValue is accepted, it will be set in the model and the func will return true
        //                                            if it's not immediately accepted, the func will return false and call preventDefault with the original value
        //                                            if it's accepted after returning, setControl will be called with the new value
        replaceUiBinding: function ($field, setControl) {
            var $ruleData = $field.prev("span.rule-data");
            var data = $ruleData.data();
            if (!data)
                return undefined;
            var fieldModel = data.fieldModel;
            if (!fieldModel)
                return undefined;
            return fieldModel.setDomHook(setControl);
        },

        selectors: {
            ruleData: ".rule-data",
            behaviorParameters: ".behavior-parameters",
            ruleParameters: ".rule-parameters",
            fieldCriteria: ".field-criteria",
            fieldChange: ".field-change",
            tagSummary: ".tag-summary",
            optionBehaviors: "option.behavior-parameters"
        }
    };

    return ruleDataViewModel;
});