﻿define("kendoEventBinder",
    [
        "jquery",
        "domSearchHelper",
        "lookupHelper",
        "pinger",
        "ruleEngine",
        "ccisEnrollmentElegibility",
        "kendo.dropdownlist.min",
        "kendo.datepicker.min",
        "kendo.numerictextbox.min",
        "kendo.timepicker.min",
        "kendo.maskedtextbox.min"
    ],
    (function ($, domSearchHelper, lookupHelper, pinger, ruleEngine, ccis) {
        var kendoEventBinder = {
            numericTextBoxDataAttributePrefix: "data-kendonumerictextbox-",

            cascadeFromClassPrefix: "cascade-from-",

            forceSelectRefreshFromClassPrefix: "force-select-refresh-from-",

            additionalFilterPrefix: "additional-filter-on-",

            jsLookupKendoClass: "js-lookup-kendo",

            init: function () {
                var self = this;

                self.bindKendoUIEvents();
            },


            bindKendoUIEvents: function ($container) {
                var self = this;

                self.bindKendoUIDatePickerFields($container);
                self.bindKendoUITimePickerFields($container);
                self.bindKendoUINumericTextBoxFields($container);
                self.bindKendoUIMaskedTextBox($container);
                self.bindKendoUICascadingDropDownList($container);
                self.bindDropDownChangeEvent();
                self.bindEPPCheckboxChangeEvent($container);
                self.bindInitialCheckboxState($container);
            },


            bindInitialCheckboxState: function ($container) {
                var self = this;

                $container = $container || $('body');

                var $eppCheckboxes = $("[id^='field-checked-21854-']");

                if ($eppCheckboxes.length > 0) {
                    self.updateBillingGroupLabel($eppCheckboxes[0], $container)
                    self.updateReprogramCheckbox($eppCheckboxes[0].checked, $container)
                }
            },

            onSelectedItem: function (e) {
                //TODO: use observer pattern to notify and decouple
                ccis.setSelectedSicCode(e);
            },
            onFilteringData: function (e, selectedDataValueField) {
                var filterValue = e.sender._prev;

                if (e.filter != undefined) {
                    var filterValue = e.filter.value;
                    if (filterValue.trim) filterValue = filterValue.trim();
                    var words = filterValue.split(" ").filter(function (entry) { return entry.trim() != ''; });

                    if (words.length > 1) {

                        var itemfilters = [];
                        for (var i = words.length - 1; i >= 0; i--) {
                            itemfilters.push({ field: selectedDataValueField, operator: "contains", value: words[i] });
                        }

                        var newFilter = {
                            logic: "or",
                            filters: itemfilters
                        };

                        e.sender.dataSource.filter(newFilter);
                        //var temp = e.sender.dataSource.view().find(x => x.Description.toLowerCase().startsWith(filterValue.toLowerCase()));
                        var temp = e.sender.dataSource.view().find(function (x) {
                            return x.Description.toLowerCase().startsWith(words[0].toLowerCase())
                        });
                        if (temp != undefined) {
                            e.sender.select(function (dataItem) {
                                return dataItem.Description === temp.Description;
                            });
                        }
                        e.preventDefault();
                    }
                } else {
                    e.preventDefault();
                }

            },


            //TODO: Add a function to call destroy() function on Kendo UI controls when removing elements from the DOM for memory management
            bindEPPCheckboxChangeEvent: function ($container) {
                var self = this;

                $container = $container || $('body');

                var $eppCheckboxes = $("[id^='field-checked-21854-']");

                $eppCheckboxes.each(function (index, element) {
                    $(element).click(function (event) {
                        self.updateBillingGroupLabel(event.target, $container)
                        self.updateReprogramCheckbox(event.target.checked, $container)
                    });
                });
            },

            updateBillingGroupLabel: function (eppCheckBox, $container) {
                var self = this;

                $container = $container || $('body');

                var $billingGroupLabel = $(".billing-group-label");

                if (eppCheckBox && eppCheckBox.checked) {
                    $billingGroupLabel.find("legend").text('Billing Applicable for shipping charges');
                }
                else if (eppCheckBox && !eppCheckBox.checked) {
                    $billingGroupLabel.find("legend").text('Billing');
                }
            },

            updateReprogramCheckbox: function (checked, $container) {
                var self = this;

                $container = $container || $('body');

                var $reprogramCheckboxes = $("[id^='field-checked-16363-']");

                $reprogramCheckboxes.each(function (index, element) {
                    var $reprogramCheckbox = $(element);

                    if (checked) {
                        if ($reprogramCheckbox[0].checked) {
                            $($reprogramCheckbox[0]).trigger('click');
                        }

                        $reprogramCheckbox[0].checked = false;
                        $reprogramCheckbox[0].disabled = true;

                        var listBox = self.getFirstControlForceSelectRefresh($container);

                        self.triggerCascadingRefreshById("#field-value-" + listBox.dataset.fieldId + "-0_listbox", $container)
                    }
                    else if (!checked) {
                        $reprogramCheckbox[0].disabled = false;
                    }
                });
            },
            getFirstControlForceSelectRefresh: function ($container) {
                var self = this;

                var $controlsForceSelectRefresh = domSearchHelper.get$ElementsWithClassPrefix($container, self.forceSelectRefreshFromClassPrefix);

                return $controlsForceSelectRefresh[0];
            },

            bindKendoUIDatePickerFields: function ($container) {
                var self = this;

                $container = $container || $('body');

                $container.find(self.selectors.kendoDatePickerInput).kendoDatePicker();

                //get controls to force-select-refresh
                var $controlsForceSelectRefresh = domSearchHelper.get$ElementsWithClassPrefix($container, self.forceSelectRefreshFromClassPrefix);

                //iterate controls to force-select-refresh
                $controlsForceSelectRefresh.each(function (_, controlForceSelectRefresh) {
                    var $controlForceSelectRefresh = $(controlForceSelectRefresh);

                    var forceSelectRefreshFromClasses = domSearchHelper.getClassesWithPrefix($controlForceSelectRefresh, self.forceSelectRefreshFromClassPrefix);

                    //iterate classes
                    $(forceSelectRefreshFromClasses).each(function (_, forceSelectRefreshClass) {

                        //get class of control to trigger force-select-refresh
                        var triggerClass = forceSelectRefreshClass.replace(self.forceSelectRefreshFromClassPrefix, "");

                        //get control to trigger force-select-refresh
                        var $triggerControl = $container.find("." + triggerClass);

                        if ($triggerControl.length === 0) {
                            // the parent $triggerControl doesn't exist in this field section, it must be in a higher scope,
                            $triggerControl = $("." + triggerClass);
                        }

                        if ($triggerControl && $triggerControl.length > 0) {
                            var $checkbox1 = $("#field-checked-" + $triggerControl[0].dataset.fieldId  + "-0");
                            if ($checkbox1) {
                                $checkbox1.click(function () {
                                    self.triggerCascadingRefreshById("#field-value-" + $controlForceSelectRefresh[0].dataset.fieldId + "-0_listbox", $container)
                                    self.triggerCascadingRefreshById("#field-value-" + $controlForceSelectRefresh[0].dataset.fieldId + "-1_listbox", $container)
                                });
                            }
                        }
                    });
                });
            },

            triggerCascadingRefreshById: function (elementId, $container) {
                $container = $container || $("body");
                var $element = $(elementId);

                if ($element.length === 0) { return; }

                try {
                    $element.data().kendoStaticList.select(0);
                    $element.data().kendoStaticList.refresh();
                }
                catch (err) {
                    console.log(err);
                }
            },

            bindKendoUITimePickerFields: function ($container) {
                var self = this;

                $container = $container || $('body');

                $container.find(self.selectors.kendoTimePickerInput).kendoTimePicker();
            },

            bindKendoUINumericTextBoxFields: function ($container) {
                var self = this;

                $container = $container || $('body');

                $container.find(self.selectors.kendoNumericTextBoxSpan).each(
                    function (index, kendoNumericTextBoxSpan) {
                        var options = {};

                        var hiddenTextInput = $(kendoNumericTextBoxSpan)
                            .find(self.selectors.kendoNumericTextBoxInputHiddenText).last().get(0);

                        //Loop through "data-kendonumerictextbox-" attributes
                        $.each(hiddenTextInput.attributes,
                            function (index, attribute) {
                                //Attribute name starts with data-kendonumerictextbox-
                                if (attribute.name.indexOf(self.numericTextBoxDataAttributePrefix) === 0) {
                                    var optionName = attribute.name.replace(self.numericTextBoxDataAttributePrefix,
                                        ""); //optionName has data-kendonumerictextbox- prefix removed
                                    options[optionName] = attribute.value;
                                }
                            });

                        $(kendoNumericTextBoxSpan).find("input[type='text']").last().kendoNumericTextBox(options);

                        $(kendoNumericTextBoxSpan).find(self.selectors.spinnerWrapper).last().remove();
                    });
            },

            bindKendoUIMaskedTextBox: function ($container) {
                var self = this;

                $container = $container || $('body');

                $container.find(self.selectors.kendoMaskedTextBox).each(function (index, kendoMaskedTextBox) {
                    var $kendoMaskedTextBox = $(kendoMaskedTextBox);
                    var mask = $kendoMaskedTextBox.data("mask");
                    var clearPromptChar = $kendoMaskedTextBox.data("clearpromptchar") || true;

                    var $parent = $kendoMaskedTextBox.parent();
                    if ($parent.is(self.selectors.kendoMaskedTextBoxSpan)) {
                        $kendoMaskedTextBox.removeClass("k-textbox");
                        $parent.replaceWith($kendoMaskedTextBox);
                    }

                    $kendoMaskedTextBox.kendoMaskedTextBox({
                        "mask": mask,
                        "clearPromptChar": clearPromptChar,
                        "rules": {}
                    });
                });

                self.bindKendoUIMaskedTextBoxBlindEntryEvents($container);
            },

            bindKendoUIMaskedTextBoxBlindEntryEvents: function ($container) {
                function setBlindInputState($this, hideValue) {
                    var field = $this.prop("tagName").toLowerCase() == "input" ? $this : $this.find("input");
                    if (hideValue) {
                        field.attr("type", "password");
                        $this.next().addClass("fa-eye").removeClass("fa-eye-slash");
                    } else {
                        field.attr("type", "text");
                        $this.next().addClass("fa-eye-slash").removeClass("fa-eye");
                    }
                }

                function initializeBlindInput() {
                    var $this = $(this);
                    var value = !!$this.find("input").data("kendoMaskedTextBox")
                        ? $this.find("input").data("kendoMaskedTextBox").raw()
                        : $this.val();
                    setBlindInputState($this, value);
                }

                function enterBlindInput() {
                    var $this = $(this);
                    var value = !!$this.find("input").data("kendoMaskedTextBox")
                        ? $this.find("input").data("kendoMaskedTextBox").raw()
                        : $this.val();
                    setBlindInputState($this, value && $this.next().hasClass("fa-eye"));
                }

                $("div.blind > span.input-field-masked, div.blind > input.dual-blind-text", $container).each(initializeBlindInput);

                $("div.blind > span.input-field-masked > input, div.blind > input.dual-blind-text", $container).focus(enterBlindInput);

                $("div.blind > span.input-field-masked > input, div.blind > input.dual-blind-text", $container).blur(function () {

                    var $this = $(this).hasClass("input-field-masked") ? $(this).parent() : $(this);

                    //var $this = $(this).parent();

                    if (!$this.next().data("toggling")) {
                        $(this).attr("type", "password");
                        $this.next().addClass("fa-eye").removeClass("fa-eye-slash");
                    }
                });

                $("div.blind > span.fa", $container).click(function () {
                    var $this = $(this);
                    setBlindInputState($this.prev(), $this.hasClass("fa-eye-slash"));
                }).on("mousedown", function () {
                    $(this).data("toggling", true);
                }).on("mouseup", function () {
                    $(this).data("toggling", false);
                });
            },

            bindKendoUIDropDownList: function ($dropDownList, options) {
                var dropdown = $dropDownList.find("select");
                var selectedValue = dropdown.data("selected-value");

                $dropDownList.kendoDropDownList(options).data("kendoDropDownList").value(selectedValue);

            },

            bindDropDownChangeEvent: function () {

                // Only applies to non kendo drop down that has data-selected-value attribute.

                $("select[data-selected-value]").not(".k-dropdown select[data-selected-value]").change(function () {

                    $(this).data("selected-value", this.value); // update data in memory, this line is sufficient to solve bug PBA-849

                    $(this).attr("data-selected-value", this.value); //update dom so dev looking at dom later wont get confused as line above wont update dom 
                });
            },
            hideTerminalPrice: function (fieldID) {
                if (fieldID === 16459 && $("[id^='field-checked-16363-']").length > 0 && !$("[id^='field-checked-16363-']")[0].checked) {
                    return false;
                }
                if ((fieldID === 16459) && $('#EnableEquipmentPricing').val().toLowerCase() === 'true') {
                    return true;
                }
                return false;
            },

            bindKendoUICascadingDropDownList: function ($containers) {
                var self = this;

                $containers = $containers || $(self.selectors.fieldSection);

                $containers.each(function (index, container) {
                    var $container = $(container);

                    var $cascadedDropDowns = domSearchHelper.get$ElementsWithClassPrefix($container, self.cascadeFromClassPrefix);

                    var dropDownMap = {};

                    function getDropDownConfig($dropDown) {
                        var fieldId = domSearchHelper.getFieldIDFromFieldClass($dropDown);
                        var config = dropDownMap[fieldId];
                        if (!config)
                            dropDownMap[fieldId] = config = {
                                dropDown: $dropDown,
                                parent: $([]),
                                children: $([]),
                                otherDependencies: $([]),
                                otherDependents: $([])
                            };
                        return config;
                    }

                    // we have to sort these so parents get initialized before children
                    var dropDownArray = [];

                    $cascadedDropDowns.each(function (_, dropDown) {
                        var $dropDown = $(dropDown);
                        var config = getDropDownConfig($dropDown);

                        var cascadeFromClass = domSearchHelper.getClassWithPrefix($dropDown, self.cascadeFromClassPrefix);
                        var parentDropDownClass = cascadeFromClass.replace(self.cascadeFromClassPrefix, "");

                        var $parentDropDown = $container.find("." + parentDropDownClass);
                        if ($parentDropDown.length === 0) {
                            // the parent dropdown doesn't exist in this field section, it must be in a higher scope,
                            // don't define parent dropdowns in a sibling scope, because that would not work
                            $parentDropDown = $("." + parentDropDownClass);
                        }

                        if ($parentDropDown.length) {
                            config.parent = config.parent.add($parentDropDown);
                            var parentConfig = getDropDownConfig($parentDropDown);
                            parentConfig.children = parentConfig.children.add($dropDown);

                            var parentFieldId = domSearchHelper.getFieldIDFromFieldClass($parentDropDown);
                            if (!dropDownArray.includes(parentFieldId))
                                dropDownArray.push(parentFieldId);
                        }

                        var fieldId = domSearchHelper.getFieldIDFromFieldClass($dropDown);
                        if (!dropDownArray.includes(fieldId))
                            dropDownArray.push(fieldId);

                        config.otherDependencies = config.otherDependencies.add($.map(domSearchHelper.getClassesWithPrefix($dropDown, self.additionalFilterPrefix),
                            function (className) {
                                var filterSelector = "." + className.replace(self.additionalFilterPrefix, "");
                                var $filterField = $container.find(filterSelector);
                                if ($filterField.length === 0) {
                                    // the filter dropdown doesn't exist in this field section, it must be in a higher scope,
                                    // don't define filter dropdowns in a sibling scope, because that would not work
                                    $filterField = $(filterSelector);
                                }
                                var filterConfig = getDropDownConfig($filterField);
                                filterConfig.otherDependents = filterConfig.otherDependents.add($dropDown);
                                return $filterField.get();
                            }));
                    });

                    var serverOptions = {
                        autoBind: true,
                        dataTextField: "OptionText",
                        dataValueField: "OptionValue",
                        optionLabel: "Select",
                        dataSource: {
                            //type: "json",
                            serverFiltering: true,
                            transport: {
                                read: {
                                    url: "/Lookup/GetLookup",
                                    cache: false
                                }
                            },
                            error: function (e) {
                                if (e.xhr.status === 403) {
                                    pinger.handleSessionExpiration(e.status, e.errorThrown);
                                }
                            }
                        }
                    };

                    var clientOptions = {
                        autoBind: true,
                        placeholder: "Select",
                        dataTextField: "optionText",
                        dataValueField: "optionValue",
                        dataSource: {}
                    };

                    var applicationPackageUID = $("#ApplicationPackageUID").val();

                    $.each(dropDownArray, function (_, fieldID) {
                        var config = dropDownMap[fieldID];
                        var $dropDown = config.dropDown;
                        var $dropDownSelect = domSearchHelper.getValueFieldFromFieldClass($dropDown);

                        if (!$dropDownSelect.is("select"))
                            return; // a read-only parent, can't set it up as a kendo dropdown because it's not a dropdown

                        var options = {};

                        if ($dropDown.hasClass(self.jsLookupKendoClass)) {
                            $.extend(options, clientOptions, { dataSource: lookupHelper.getJsOptionsForFieldElement($dropDownSelect) });
                            if (config.parent.length)
                                $.extend(options, { autoBind: false, cascadeFromField: "parentID" });
                        } else {
                            var fieldLookupSourceID = domSearchHelper.getFieldLookupSourceIDFromFieldClass($dropDown);
                            if (fieldLookupSourceID === 0 || fieldLookupSourceID === 69) //Backend field, this is awful, but we ran out of time, create a technical debt card for it :/
                                return; // we won't make this a kendo dropdown and there's nothing more to do

                            $.extend(true, options, serverOptions);
                            if ((fieldID === 16459 || fieldID === 21884) && $('#EnableEquipmentPricing').val().toLowerCase() === 'true')  {
                                var extOptions = {
                                    template: '<div class="equipment-terminal-field-width"><div class="equipment-terminal-field-width-optiontext">${OptionText}</div><div class="equipment-terminal-field-width-optionprice">${EquipmentPrice}</div></div>'
                                }
                                $.extend(true, options, extOptions);
                            }

                            var fieldLookupID = domSearchHelper.getFieldLookupIDFromFieldClass($dropDown);

                            var filters = {};

                            var agentFieldElement = $("#" + self.selectors.agentField);

                            if (agentFieldElement.length > 0) {
                                filters[self.selectors.SalesOfficeNumber] = agentFieldElement[0].value;
                            }

                            filters['IsEPPDeployment'] = $(self.selectors.isEPPDeployment).val() === undefined ? false : $(self.selectors.isEPPDeployment).val();
                            var relationshipAncestorUIDElements = $('.' + self.selectors.relationshipAncestorUID);

                            if (relationshipAncestorUIDElements.length > 0) {
                                filters[self.selectors.relationshipAncestorUID] = relationshipAncestorUIDElements[0].value;
                            }

                            options.dataSource.transport.read.data =
                                !config.parent.length ? function () { 
                                        return {
                                            fieldID: fieldID,
                                            fieldLookupID: fieldLookupID,
                                            fieldLookupSourceID: fieldLookupSourceID,
                                            filterValue: JSON.stringify(filters),
                                            applicationPackageUID: applicationPackageUID
                                        };
                                    }
                                    : !config.otherDependencies.length ? function () {
                                        var fieldId = domSearchHelper.getFieldIDFromFieldClass(config.parent);

                                        filters[fieldId] = domSearchHelper.getValueFieldFromFieldClass(config.parent).data("selected-value");
                                        filters[self.selectors.brandUID] = domSearchHelper.getValueFieldFromFieldClass(config.parent).data("selected-value");

                                        var equipmentUIDElements = $("." + self.selectors.equipmentUID);

                                        if (equipmentUIDElements.length > 0) {
                                            filters[self.selectors.equipmentUID] = relationshipAncestorUIDElements[0].value;
                                        }

                                        return {
                                            fieldID: fieldID,
                                            fieldLookupID: fieldLookupID,
                                            fieldLookupSourceID: fieldLookupSourceID,
                                            filterValue: JSON.stringify(filters),
                                            applicationPackageUID: applicationPackageUID
                                        };
                                    }
                                        : function () {
                                            var fieldIds = [];

                                            // the one cascade-from is always a filter
                                            var parentFieldID = domSearchHelper.getFieldIDFromFieldClass(config.parent);
                                            filters[parentFieldID] = [config.parent];
                                            fieldIds.push(parentFieldID);

                                            // additional-filter-on might have multiple fields per class because they're in a collection,
                                            // but we want to treat all fields of the same class together
                                            // make one property on filters per class
                                            config.otherDependencies.each(function (__, e) {
                                                var $this = $(e);
                                                filters[domSearchHelper.getFieldIDFromFieldClass($this)] = [];
                                            });

                                            // put the fields in the filters for the class
                                            config.otherDependencies.each(function (__, e) {
                                                var $this = $(e);
                                                var dependencyFieldId = domSearchHelper.getFieldIDFromFieldClass($this);

                                                filters[dependencyFieldId].push($this);
                                                fieldIds.push(dependencyFieldId);
                                            });

                                            // if there's one field for a class, just get its value, if there are multiple,
                                            // get all the values except the one that's in the same collection instance as the child field
                                            var $valueField;
                                            var value;
                                            for (var f = 0; f < fieldIds.length; f++) {
                                                var filterFieldId = fieldIds[f];
                                                if (filters[filterFieldId].length === 1) {
                                                    $valueField = domSearchHelper.getValueFieldFromFieldClass(filters[filterFieldId][0]);
                                                    value = $valueField.data("selected-value");
                                                    if (value === undefined) {
                                                        $valueField = domSearchHelper.getCheckedFieldFromFieldClass(filters[filterFieldId][0]);
                                                        if ($valueField.length === 1) value = $valueField.is(':checked');
                                                    }
                                                    if (value === undefined) value = $valueField.val();
                                                    filters[filterFieldId] = value;
                                                } else {
                                                    // find the common ancestors we have with each of these "parents", one of them will have one that the others don't,
                                                    // remove that one because it's really a sibling
                                                    var parentArray = filters[filterFieldId];
                                                    var mostCommonAncestorsIndex = 0;
                                                    var mostCommonAncestors = $dropDown.parents().has(parentArray[0]).length;
                                                    for (var a = 1; a < parentArray.length; a++) {
                                                        var commonAncestors = $dropDown.parents().has(parentArray[a]).length;
                                                        if (commonAncestors > mostCommonAncestors) {
                                                            mostCommonAncestorsIndex = a;
                                                            mostCommonAncestors = commonAncestors;
                                                        }
                                                    }
                                                    var valueArray = [];
                                                    for (var p = 0; p < parentArray.length; p++) {
                                                        if (p !== mostCommonAncestorsIndex) {
                                                            $valueField = domSearchHelper.getValueFieldFromFieldClass(parentArray[p]);
                                                            value = $valueField.data("selected-value");
                                                            if (value === undefined) value = $valueField.val();
                                                            valueArray.push(value);
                                                        }
                                                    }
                                                    filters[filterFieldId] = valueArray;
                                                }
                                            }

                                            return {
                                                fieldID: fieldID,
                                                fieldLookupID: fieldLookupID,
                                                fieldLookupSourceID: fieldLookupSourceID,
                                                filterValue: JSON.stringify(filters),
                                                applicationPackageUID: applicationPackageUID
                                            };
                                        };
                        }

                        if (config.parent.hasClass(self.jsLookupKendoClass) || domSearchHelper.getFieldLookupSourceIDFromFieldClass(config.parent))
                            options.cascadeFrom = domSearchHelper.getValueFieldIDFromFieldClass(config.parent);

                        function getDescendentSelects($accumulator, $children) {
                            $children.each(function () {
                                var childConfig = getDropDownConfig($(this));
                                $accumulator = getDescendentSelects($accumulator.add(childConfig.dropDown.find("select")), childConfig.children);
                            });
                            return $accumulator;
                        }

                        var $descendents = getDescendentSelects($([]), config.children);

                        var clearDescendents = $descendents.length
                            ? function (value) {
                                if (!value) {
                                    $descendents.each(function () {
                                        var $this = $(this);
                                        $this.data("previous-value", $this.data("selected-value"));
                                    });
                                    $descendents.data("selected-value", "");
                                }
                            }
                            : $.noop;

                        var notifyDependents = config.otherDependents.length
                            ? function () {
                                config.otherDependents.find("select").each(function () {
                                    $(this).data("kendoDropDownList").dataSource.read();
                                });
                            }
                            : $.noop;

                        function finalizeSetting(value) {
                            $dropDownSelect.data("selected-value", value);
                            clearDescendents(value);
                            notifyDependents();
                        }

                        function setValue(value) {
                            $dropDownSelect.data("kendoDropDownList").value(value);
                            $dropDownSelect.data("selected-value", value);
                            $dropDownSelect.change();
                            clearDescendents(value);
                            notifyDependents();
                            return value;
                        }
                        function setPeripharalPrice(fieldId, e) {
                            if (fieldId !== 21884 || $('#EnableEquipmentPricing').val().toLowerCase() !== 'true')
                                return;
                            var priceValue = e.sender.dataItem(e.item).EquipmentPrice === undefined ? 0 : e.sender.dataItem(e.item).EquipmentPrice;
                            if ($('#field-value-21889-0').length > 0)
                                $('#field-value-21889-0').val(priceValue);
                        }

                        var onChange = ruleEngine.replaceUiBinding($dropDown, setValue);
                        options.select = onChange // undefined if no rules on this dropdown
                            ? function (e) {
                                var value = e.sender.dataItem(e.item).OptionValue;
                                setPeripharalPrice(fieldID, e);
                                if (onChange(value, function (oldValue) { e.preventDefault(); })) // no idea why preventDefault has to be wrapped, but it doesn't work if it's not
                                    finalizeSetting(value);
                            }
                            : function (e) {
                                var value = e.sender.dataItem(e.item).OptionValue;
                                finalizeSetting(value);
                            };

                        var autoSelectFirst = $dropDown.hasClass(self.selectors.autoSelectOnlyOption) ? false
                            : $dropDown.hasClass(self.selectors.autoSelectFirstOption) ? true
                                : null;

                        // hiding the child when its only option is the same as its parent is only valid when we're auto-selecting the only option
                        var hideEqualOnlyOption = $dropDown.hasClass(self.selectors.hideEqualOnlyOption);

                        var ignoreAttributes = ["_handlers", "_events", "uid", "parent"];

                        options.dataBound = function (e) {
                            var data = $dropDownSelect.data();
                            var selectedValue = data.previousValue === undefined ? data.selectedValue : data.previousValue;
                            delete data.previousValue;

                            var kendoDropDownList = e.sender;
                            var dataItems = kendoDropDownList.dataSource.data();

                            var selectedValueExists = false;
                            var maxLength = 0;
                            var maxWidth = $dropDown.width();

                            var currentOption = $dropDownSelect.find("option:first");
                            if (currentOption.text() === "Select")
                                currentOption = currentOption.next();

                            if (!dataItems.length)
                                kendoDropDownList.enable(false);
                            else {
                                for (var i = 0; i < dataItems.length; i++) {
                                    var dataAttributes = dataItems[i].DataAttributes;
                                    for (var attr in dataAttributes) {
                                        if (dataAttributes.hasOwnProperty(attr) && !ignoreAttributes.includes(attr))
                                            currentOption.attr("data-" + attr, dataAttributes[attr]);
                                    }

                                    if (!selectedValueExists && currentOption.val() === selectedValue)
                                        selectedValueExists = true;

                                    var length = dataItems[i].OptionText.length;
                                    if (length > maxLength)
                                        maxLength = length;

                                    currentOption = currentOption.next();
                                }
                            }

                            if (!selectedValueExists && autoSelectFirst !== null) {
                                if (autoSelectFirst) {
                                    if (dataItems.length > 0) data.selectedValue = dataItems[0].OptionValue;
                                } else if (dataItems.length === 1) {
                                    data.selectedValue = dataItems[0].OptionValue;

                                    if (hideEqualOnlyOption) {
                                        if (dataItems[0].OptionText === config.parent.find("option:selected").text())
                                            $dropDown.addClass("hidden");
                                        else
                                            $dropDown.removeClass("hidden");
                                    }
                                } else
                                    data.selectedValue = "";
                            }

                            maxLength = maxLength * 8.25;
                            if (maxLength > maxWidth) {
                                maxLength = maxWidth;
                            }

                            if (maxLength < 100) {
                                maxLength = 100;
                            }
                            if ((fieldID === 16459) && $('#EnableEquipmentPricing').val().toLowerCase() === 'true') { // max value Only for Terminal Model window
                                maxLength = 170;
                                var itemss = kendoDropDownList.items();
                                if(self.hideTerminalPrice(fieldID))
                                    $(itemss).find('div.equipment-terminal-field-width-optionprice').addClass('hidden');
                                else
                                    $(itemss).find('div.equipment-terminal-field-width-optionprice').removeClass('hidden');
                            }
                            setPeripharalPrice(fieldID, e);
                            kendoDropDownList.span.width(maxLength);

                            if (kendoDropDownList.value() !== data.selectedValue)
                                kendoDropDownList.value(data.selectedValue);


                            if (data.initializing) {
                                if (onChange)
                                    onChange(data.selectedValue);
                                else
                                    $dropDownSelect.trigger({ type: "change", initializing: true });

                                delete data.initializing;
                                return;
                            }

                            if (selectedValue === data.selectedValue)
                                return;

                            if (onChange)
                                onChange(data.selectedValue);
                            $dropDownSelect.change();
                            $dropDownSelect.blur();

                            notifyDependents();
                        };

                        $dropDownSelect.data("initializing", true)
                            .kendoDropDownList(options)
                            .data("kendoDropDownList")
                            .enable(true);
                    });
                });
            },

            selectors: {
                kendoDatePickerInput: ".k-datepicker input",
                kendoTimePickerInput: ".k-timepicker input",
                spinnerWrapper: "span.k-select",
                kendoNumericTextBoxSpan: ".k-numerictextbox",
                kendoNumericTextBoxInputText: ".k-numerictextbox input[type='text']",
                kendoNumericTextBoxInputHiddenText: ".k-numeric-wrap input[type='text']:hidden",
                //kendoMaskedTextBox: ".k-textbox[data-role='maskedtextbox']",
                kendoMaskedTextBox: ".field-masked-text input.input-field-masked",
                kendoMaskedTextBoxSpan: "span.k-maskedtextbox",
                fieldSection: ".field-section",
                autoSelectOnlyOption: "auto-select-only-option",
                autoSelectFirstOption: "auto-select-first-option",
                hideEqualOnlyOption: "hide-equal-only-option",
                field: ".field",
                brandUID: "brandUID",
                relationshipAncestorUID: "relationshipAncestorUID",
                agentField: "field-value-16429-0",
                SalesOfficeNumber: "SalesOfficeNumber",
                equipmentUID: "equipmentUID",
                isEPPDeployment:".IsEPPDeployment"
            }
        };

        return kendoEventBinder;
    }));