﻿define("pinger", ["jquery", "bootstrap", "notificationPopupHelper", "utils"], (function ($, bootstrap, notificationPopupHelper, utils) {
    var pinger = {
        intervalHandle: null,
        failuresSoFar: 0,
        maxAllowedFailures: 3,
        pingIntervalMilliSeconds: 60000,
        validateUrl: "/sso/ValidateUserToken",
        redirectUrl: "/sso/login",

        init: function () {
            var self = this;
            var pingIntervalMilliSeconds = $(".jsValuesPing").data("pingIntervalMilliSeconds");

            if (utils.isNumber(pingIntervalMilliSeconds)) {
                self.pingIntervalMilliSeconds = pingIntervalMilliSeconds;
            }

            self.intervalHandle = window.setInterval(function () {
                self.ping();
            }, self.pingIntervalMilliSeconds);
        },

        ping: function () {
            var self = this;

            $.ajax({
                url: self.validateUrl,
                type: "GET"
            }).success(function (data) {
                if (data != undefined && data.result != undefined && data.result.toString().toLowerCase() !== "true") {
                    self.handleSessionExpiration();
                }
            })
            .error(function (jqXHR, textStatus, errorThrown) {
                self.failuresSoFar++;

                if (self.failuresSoFar > self.maxAllowedFailures) {
                    self.handleSessionExpiration(textStatus, errorThrown);
                }
                else {
                    //Tolerate errors
                }
            });
        },

        handleSessionExpiration: function (textStatus, errorThrown) {
            var self = this;

            window.clearInterval(self.intervalHandle);

            var message = "Your session has expired or you have logged in on another place. Please login to continue.";

            if (textStatus) {
                message += "<br><br>" + "textStatus: " + textStatus + "<br>" + "errorThrown:" + errorThrown;
            }

            notificationPopupHelper.showErrorPopup("Session Expired", message);

            $('.k-widget.k-window .k-i-close').click(function () {
                window.location.href = self.redirectUrl;
            });

            // Automatically send to login page after 3 times the ping interval
            window.setTimeout(function () {
                window.location.href = self.redirectUrl;
            }, self.pingIntervalMilliSeconds * 3);
        }
    }

    return pinger;
}));