﻿define("notificationPopupHelper", ['jquery'], (function ($) {
    var notificationPopupHelper = {
        init: function () {
        },

        showPopup: function (title, message) {
            var windowElement = $(this.selectors.popupWindow).data('kendoWindow');

            windowElement.setOptions({
                modal: true,
                resizable: false,
                draggable: true,
                visible: false,
                width: 400,
                height: 100
            });

            windowElement.title(title);

            windowElement.content(message);

            windowElement.center().open();

            $(".k-close").click(function () {
                windowElement.close();
            });
        },

        showErrorPopup: function (title, message) {
            var windowElement = $(this.selectors.popupWindow).data('kendoWindow');

            windowElement.setOptions({
                width: 400,
                height: 100
            });

            windowElement.title(title);

            windowElement.content(message);

            windowElement.center().open();

            $(".k-close").click(function () {
                windowElement.close();
            });
        },

        showNetworkErrorPopup: function (xhr) {
            var message = "Error occurred - ";

            if (xhr.status === 0) {
                message += 'Not connected. Verify Network connections.';
            } else if (xhr.status === 206) {
                message += 'Request returned partial content [206].';
            } else if (xhr.status === 302) {
                message += 'Request is unauthorized [302].';
            } else if (xhr.status === 401) {
                message += 'Request is unauthorized [401].';
            } else if (xhr.status === 403) {
                message += 'Request is forbidden [403].';
            } else if (xhr.status === 404) {
                message += 'Requested page not found [404].';
            } else if (xhr.status === 404) {
                message += 'HTTP method not allowed [405].';
            } else if (xhr.status === 413) {
                message += 'Request entity too large [413].';
            } else if (xhr.status === 414) {
                message += 'Request URI too long [414].';
            } else if (xhr.status === 500) {
                message += 'Internal Server Error [500].';
            } else if (xhr.status === 501) {
                message += 'Not Implemented [501].';
            } else if (xhr.status === 502) {
                message += 'Bad Gateway [502].';
            } else if (xhr.status === 503) {
                message += 'Service unavailable [503].';
            } else if (xhr.status === 504) {
                message += 'Gateway Timeout [504].';
            } else if ((xhr.exception != null) && (xhr.exception === 'parsererror')) {
                message += 'Failure when parsing JSON data.';
            } else if ((xhr.exception != null) && (xhr.exception === 'timeout')) {
                message += 'Ajax Timeout error.';
            } else if ((xhr.exception != null) && (xhr.exception === 'abort')) {
                message += 'Ajax request aborted.';
            }
            else
                message = "Received HTTP status code " + xhr.status + ".";

            this.showErrorPopup("Network Error Occurred", message);
        },

        showUnsuccessfulUpdatePopup: function () {
            var title = "Update Failed";

            var windowElement = $(this.selectors.popupWindow).data('kendoWindow');

            windowElement.setOptions({
                modal: true,
                resizable: false,
                draggable: true,
                visible: false,
                width: 400,
                height: 100
            });

            windowElement.title(title);

            windowElement.content("Your information has not updated successfully.  Please correct the errors.");

            windowElement.center().open();

            setTimeout(function () {
                windowElement.close();
            }, 3000);

            $(".k-close").click(function () {
                windowElement.close();
            });
        },

        showSuccessConfirmation: function (title, notifications) {
            var i,
                text = "Your information has updated successfully.";

            if (notifications != undefined && notifications.length > 0) {
                text += "<br /><br />Notifications:<br /><ul>";

                for (i = 0; i < notifications.length; i++) {
                    text += '<li>' + notifications[i].Description + '</li>';
                }

                text += "</ul>";
            }

            var windowElement = $(this.selectors.popupWindow).data('kendoWindow');

            windowElement.setOptions({
                modal: true,
                resizable: false,
                draggable: true,
                visible: false,
                width: 400,
                height: 150
            });

            windowElement.title(title);

            windowElement.content(text);

            windowElement.center().open();

            //don't close window automatically if there are notifications
            if (notifications === undefined || notifications === null || notifications.length === 0) {
                setTimeout(function () {
                    windowElement.close();
                }, 3000);
            }

            $(".k-close").click(function () {
                windowElement.close();
            });
        },
        confirmSubmit: function (dialogOptions, confirmedCallback) {
            var confirmWindow = $('<div class="confirmWindow" />').kendoWindow({
                title: "Confirm",
                resizable: false,
                modal: true
            });
            var defaults = {
                message: 'Are you sure you would like to continue?',
                ok: 'Ok',
                cancel: 'Cancel'
            };

            if (dialogOptions == null)
                dialogOptions = defaults;
            else {
                if (!dialogOptions.hasOwnProperty('message'))
                    dialogOptions.message = defaults.message;
                if (!dialogOptions.hasOwnProperty('ok'))
                    dialogOptions.ok = defaults.ok;
                if (!dialogOptions.hasOwnProperty('cancel'))
                    dialogOptions.cancel = defaults.cancel;
            }

            var confirmation = $('<div class="confirmationBody">' +
                '<div class="confirmText">' +dialogOptions.message +
                '</div>' +
                '<div class="pull-right">' +
                '<br/>'+
                '<button class="confirmOk btn btn-primary">'+dialogOptions.ok+'</button> ' +
                '<button class="confirmCancel btn btn-default">'+dialogOptions.cancel+'</button>' +
                '</div>' +
                '</div>');

            var args = arguments;
            $(confirmation).on('click', '.confirmOk', function (e) {
                confirmWindow.data("kendoWindow").close();
                if (confirmedCallback) {
                    if (args.length > 2) {
                        confirmedCallback.apply(undefined, Array.prototype.slice.call(args, 2));
                    }else {
                        confirmedCallback();
                    }
                }
            });

            $(confirmation).on('click', '.confirmCancel', function (e) {
                confirmWindow.data("kendoWindow").close();
            });

            confirmWindow.data("kendoWindow")
                .content(confirmation)
                .center().open();

        },

        selectors: {
            popupWindow: "#NotificationPopupWindow"
        }
    };

    return notificationPopupHelper;
}));