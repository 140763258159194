﻿define("lookupHelper", ['jquery'/*, 'jsLookups'*/, 'equipmentPeripheralHelper'], (function ($/*, jsLookups*/, equipmentPeripheralHelper) {
    var lookupHelper = {

        init: function () {
            var self = this;

            self.bindEvents();
            self.buildLookups($("form"));
        },

        bindEvents: function() {
            var self = this;

            self.bindSetDefaultValues();
        },

        bindSetDefaultValues: function() {
            //var self = this;

            //$('body').on('change', self.selectors.jsLookupFields, function () {
            //    var $field = $(this);

            //});
        },

        buildLookups: function($container) {
            var self = this;

            self.buildDropDownLookupValues($container);
            self.buildJsLookupOptions($container);
        },

        buildDropDownLookupValues: function ($container) {
            var self = this;

            var $fieldSelects = $container.find(self.selectors.ajaxLookupFields + " select");

            $fieldSelects.each(function (index, fieldSelect) {
                var $fieldSelect = $(fieldSelect);

                var $field = $fieldSelect.closest(self.selectors.field);

                var fieldID = $field.data("fieldId");
                var fieldLookupID = $field.data("fieldLookupId");
                var fieldLookupSourceID = $field.data("fieldLookupSourceId");
                var applicationPackageUID = $("#ApplicationPackageUID").val();

                var uri = "/Lookup/GetLookup?fieldID=" + fieldID + "&fieldLookupID=" + fieldLookupID + "&fieldLookupSourceID=" + fieldLookupSourceID + "&filterValue=";

                if (applicationPackageUID !== undefined) {
                    uri = uri + "&applicationPackageUID=" + applicationPackageUID;
                }

                var selectedValue = $fieldSelect.data("selected-value");

                $fieldSelect.find("option:not(:first)").remove(); //Clear all the option but leave default dropdown text 'Select' behind

                $.getJSON(uri, function (data) {
                    $.each(data, function (key, val) {
                        if (data.length === 1 && $fieldSelect.data("selected-value") === "") {
                            $fieldSelect.data("selected-value", val.OptionValue);
                        }
                        
                        var selected = "";
                        if ((selectedValue !== undefined) && (selectedValue !== null) && (selectedValue === val.OptionValue)) {
                            selected = "selected";
                        }

                        var option = "<option value='" + val.OptionValue + "' " + selected + ">" + val.OptionText + "</option>";
                        
                        $fieldSelect.append(option);
                    });
                })
                .done(function () {
                    var selectedValue = $fieldSelect.data("selected-value");
                    if ((selectedValue !== undefined) && (selectedValue !== null)) {
                        if (selectedValue.match(/^ *$/) !== null) {
                            $fieldSelect.val(selectedValue);
                        }
                    }
                    equipmentPeripheralHelper.initDeployCheckboxBasedonPeripheral($fieldSelect);
                });

            });
        },

        buildJsLookupOptions: function ($container) {
            var self = this;

            var $fieldSelects = $container.find(self.selectors.jsLookupFields + " select");

            $fieldSelects.each(function (index, fieldSelect) {
                var $fieldSelect = $(fieldSelect);
                var jsLookupsName = $fieldSelect.attr("data-jslookups-name");

                if (jsLookupsName.length > 0) {
                    var options = self.getJsOptionsForFieldElement($fieldSelect);

                    $fieldSelect.empty();

                    $.each(options, function (key, val) {
                        var option = "<option value='" + val.optionValue + "'>" + val.optionText + "</option>";
                        $fieldSelect.append(option);
                    });
                }
            });
        },

        getJsOptionsForFieldElement: function ($fieldElement) {
            //var self = this;
            var options = [];

            var jsLookupsName = $fieldElement.attr("data-jslookups-name");

            if (jsLookupsName && jsLookupsName.length > 0) {

                if (jsLookupsName.indexOf("genericLookups") === 0) {
                    var genericLookupMemberName = jsLookupsName.replace("genericLookups.", "");
                    options = jsLookups.genericLookups[genericLookupMemberName];
                }
                else {
                    options = jsLookups[jsLookupsName];
                }
            }

            return options;
        },

        getJsLookupName: function ($fieldElement) {
            //var self = this;
            var jsLookupName = $fieldElement.attr("data-jslookups-name");

            return jsLookupName;
        },

        selectors: {
            ajaxLookupFields: ".ajax-lookup",
            jsLookupFields: ".js-lookup",
            field: ".field"
    }
    };

    return lookupHelper;
}));