﻿define("equipmentPeripheralHelper", ['jquery', 'domSearchHelper'], (function ($, domSearchHelper) {
    var equipmentPeripheralHelper = {
      
        init: function () {
            var self = this;

            self.bindEvents();
            self.initState();
        },

        bindEvents: function () {
            var self = this;

            self.bindPeripheralsToDeployCheckboxes();
        },

        bindPeripheralsToDeployCheckboxes: function () {
            var self = this;

            $(self.selectors.fieldPeripheralSelect).each(function () {
                var $fieldPeripheralSelect = $(this);
                self.initDeployCheckboxBasedonPeripheral($fieldPeripheralSelect);
            });
        },

        initDeployCheckboxBasedonPeripheral: function ($fieldPeripheralSelect) {
            var self = this;

            self.adjustDeployCheckboxBasedonPeripheral($fieldPeripheralSelect, false);

            $fieldPeripheralSelect.change(function (e) {
                // e.initializing is from loadEquipmentAjaxViewModel and kendoEventBinder
                self.adjustDeployCheckboxBasedonPeripheral($fieldPeripheralSelect, !e.initializing);
            });
        },

        setDeployCheckboxBasedonPeripheral: function ($fieldPeripheralSelect) {
            var self = this;

            self.adjustDeployCheckboxBasedonPeripheral($fieldPeripheralSelect, true);
        },

        adjustDeployCheckboxBasedonPeripheral: function($fieldPeripheralSelect, forceCheck) {
            var self = this;

            var $fieldGroup = domSearchHelper.get$GroupFromContainedElement($fieldPeripheralSelect);
            var $deployPeripheralCheckbox = $fieldGroup.find(self.selectors.fieldDeployPeripheralCheckbox);

            var fieldPeripheralSelectedValue = $fieldPeripheralSelect.val();
            var fieldPeripheralSelectedText = $fieldPeripheralSelect.find("option:selected").text();

            if (fieldPeripheralSelectedValue === "" || fieldPeripheralSelectedText === "Integrated") {
                $deployPeripheralCheckbox.prop("disabled", true).prop("checked", false);
            } else {
                $deployPeripheralCheckbox.prop("disabled", false);
                if (forceCheck)
                {
                    $deployPeripheralCheckbox.prop("checked", true);
                }
            }
        },

        initState: function () {
            //var self = this;

            // initDeployCheckboxBasedonPeripheral is called by lookupHelper when each select is initialized
            //$(self.selectors.fieldPeripheralSelect).trigger("change");
        },

        selectors: {
            fieldPeripheralSelect: "[class*='field-peripheral-'] select",
            fieldDeployPeripheralCheckbox: ".field-deploy-peripheral input[type='checkbox']"
        }
        
    };

    return equipmentPeripheralHelper;
}));