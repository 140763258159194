﻿require("matchmedia-polyfill")

define("kendoInlineScripts", [
    "kendoEventBinder",
    "kendo.aspnetmvc.min",
    "kendo.autocomplete.min",
    "kendo.dropdownlist.min",
    "kendo.datepicker.min",
    "kendo.grid.min",
    "kendo.numerictextbox.min",
    "kendo.timepicker.min",
    "kendo.maskedtextbox.min",
    "kendo.tooltip.min",
    "kendo.window.min"
], kendoInit);